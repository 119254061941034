import React from 'react';
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';
import { useBulkImportCsv } from '../../hooks/employee/useBulkImportCsv';

const fields = [
	{
		label: 'Title',
		key: 'title',
		alternateMatches: ['title'],
		fieldType: {
			type: 'input',
		},
		example: 'Mr',
		validations: [
			{
				rule: 'required',
				errorMessage: 'Title is required',
				level: 'error',
			},
			{
				rule: 'regex',
				value: /^(Mr|Ms|Miss|Mrs|Sir|Dr|Lady|Lord)$/,
				errorMessage: 'Title must be one of the following: Mr, Ms, Miss, Mrs, Sir, Dr, Lady or Lord. DO NOT INCLUDE dots or commas.',
				level: 'error',
			}
		],
	},
	{
		// Visible in table header and when matching columns.
		label: 'First Name',
		// This is the key used for this field when we call onSubmit.
		key: 'first_name',
		// Allows for better automatic column matching. Optional.
		alternateMatches: ['first name'],
		// Used when editing and validating information.
		fieldType: {
			// There are 3 types - "input" / "checkbox" / "select".
			type: 'input',
		},
		// Used in the first step to provide an example of what data is expected in this field. Optional.
		example: 'Jonh',
		// Can have multiple validations that are visible in Validation Step table.
		validations: [
			{
				// Can be "required" / "unique" / "regex"
				rule: 'required',
				errorMessage: 'First Name is required',
				// There can be "info" / "warning" / "error" levels. Optional. Default "error".
				level: 'error',
			},
		],
	},
	{
		label: 'Last Name',
		key: 'last_name',
		alternateMatches: ['last Name'],
		fieldType: {
			type: 'input',
		},
		example: 'Wood',
		validations: [
			{
				rule: 'required',
				errorMessage: 'Last Name is required',
				level: 'error',
			},
		],
	},
	{
		label: 'NI Number',
		key: 'ni_number',
		alternateMatches: ['ni number', 'NI Number'],
		fieldType: {
			type: 'input',
		},
		example: 'AA121212A',
		validations: [
			{
				rule: 'regex',
				value:
					/^(?:([ACEHJLMOPRSWXY][A-CEGHJ-NPR-TW-Z]|B[A-CEHJ-NPR-TW-Z]|G[ACEGHJ-NPR-TW-Z]|[KT][A-CEGHJ-MPR-TW-Z]|N[A-CEGHJL-NPR-SW-Z]|Z[A-CEGHJ-NPR-TW-Y])[0-9]{6}[A-D])?$/,
				errorMessage: 'NI Number is invalid',
				level: 'error',
			},
		],
	},
	{
		label: 'Gender',
		key: 'gender',
		alternateMatches: ['gender'],
		fieldType: {
			type: 'input',
		},
		example: 'Male',
		validations: [
			{
				rule: 'required',
				errorMessage: 'Gender is required',
				level: 'error',
			},
			{
				rule: 'regex',
				value: /^(Male|Female|male|female|M|F|m|f)$/,
				errorMessage:
					'Gender must be one of the following: Male, Female, male, female, m, f, M or F.',
			},
		],
	},
	{
		label: 'Mobile',
		key: 'mobile',
		alternateMatches: ['mobile'],
		fieldType: {
			type: 'input',
		},
		example: '',
	},
	{
		label: 'Email',
		key: 'email',
		alternateMatches: ['email'],
		fieldType: {
			type: 'input',
		},
		example: 'worker@example.com',
		validations: [
			{
				rule: 'required',
				errorMessage: 'Email is required',
				level: 'error',
			},
			{
				rule: 'regex',
				value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
				errorMessage: 'Email must be valid',
				level: 'error',
			},
			{
				rule: 'unique',
				errorMessage: 'Email must be unique',
				level: 'error',
			},
		],
	},
	{
		label: 'Date Of Birth',
		key: 'date_of_birth',
		alternateMatches: ['date of birth', 'Date Of Birth'],
		fieldType: {
			type: 'input',
		},
		example: '2024-01-21',
		validations: [
			{
				rule: 'required',
				errorMessage: 'Date Of Birth is required',
				level: 'error',
			},
			{
				rule: 'regex',
				value: /^(?:(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])|([0-2]?\d|3[01])\/(0[1-9]|1[0-2])\/\d{4})$/,
				errorMessage: 'Date Of Birth must be valid. Format: yyyy-mm-dd or dd/mm/yyyy',
				level: 'error',
			},
		],
	},
	{
		label: 'NI Category',
		key: 'ni_category',
		alternateMatches: ['ni category', 'NI Category'],
		fieldType: {
			type: 'input',
		},
		example: 'A',
		validations: [
			{
				rule: 'required',
				errorMessage: 'NI Category is required',
			},
			{
				rule: 'regex',
				value: /^([ABCDEFHIJKLMNSVXZ])$/,
				errorMessage:
					'NI Category must be: A, B, C, D, E, F, H, I, J, K, L, M, N, S, V, X or Z',
			},
		],
	},
	{
		label: 'Address Line 1',
		key: 'address_line1',
		alternateMatches: ['address line 1'],
		fieldType: {
			type: 'input',
		},
		example: '52 My Road',
		validations: [
			{
				rule: 'required',
				errorMessage: 'Address Line 1 is required',
				level: 'error',
			},
		],
	},
	{
		label: 'Address Line 2',
		key: 'address_line2',
		alternateMatches: ['address line 2'],
		fieldType: {
			type: 'input',
		},
		example: '',
	},
	{
		label: 'City',
		key: 'address_city',
		alternateMatches: ['city'],
		fieldType: {
			type: 'input',
		},
		example: 'Myton',
		validations: [
			{
				rule: 'required',
				errorMessage: 'City is required',
				level: 'error',
			},
		],
	},
	{
		label: 'Postcode',
		key: 'address_postcode',
		alternateMatches: ['postcode'],
		fieldType: {
			type: 'input',
		},
		example: 'AA1 1AA',
		validations: [
			{
				rule: 'required',
				errorMessage: 'Postcode is required',
				level: 'error',
			},
		],
	},
	{
		label: 'Country',
		key: 'address_country',
		alternateMatches: ['country'],
		fieldType: {
			type: 'input',
		},
		example: '',
		validations: [
			{
				rule: 'required',
				errorMessage: 'Country is required',
				level: 'error',
			},
		],
	},
	{
		label: 'Payroll ID',
		key: 'payroll_id',
		alternateMatches: ['payroll id', 'Payroll ID'],
		fieldType: {
			type: 'input',
		},
		example: 'EC1',
		validations: [
			{
				rule: 'required',
				errorMessage: 'Payroll ID is required',
				level: 'error',
			},
			{
				rule: 'unique',
				errorMessage: 'Payroll ID must be unique',
				level: 'error',
			},
		],
	},
	{
		label: 'Employment Start Date',
		key: 'start_date',
		alternateMatches: ['employment start date', 'Employment Start Date'],
		fieldType: {
			type: 'input',
		},
		example: '2022-10-01',
		validations: [
			{
				rule: 'required',
				errorMessage: 'Employment Start Date is required.',
				level: 'error',
			},
			{
				rule: 'regex',
				value: /^(?:(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])|([0-2]?\d|3[01])\/(0[1-9]|1[0-2])\/\d{4})$/,
				errorMessage: 'Employment Start Date must be valid. Format: yyyy-mm-dd or dd/mm/yyyy',
				level: 'error',
			},
		],
	},
	{
		label: 'Pay Schedule',
		key: 'pay_schedule',
		alternateMatches: ['pay schedule'],
		fieldType: {
			type: 'input',
		},
		example: 'Monthly Schedule',
		validations: [
			{
				rule: 'required',
				errorMessage: 'Pay Schedule is required',
				level: 'error',
			},
		],
	},
	{
		label: 'Annual Salary',
		key: 'annual_salary',
		alternateMatches: ['annual salary', 'Annual Salary'],
		fieldType: {
			type: 'input',
		},
		example: '65000.33',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'Annual Salary must be a number between 0 and 300 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'Hourly Salary',
		key: 'hourly_salary',
		alternateMatches: ['hourly salary', 'Hourly Salary'],
		fieldType: {
			type: 'input',
		},
		example: '30.53',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,2}|1\d{3}|2000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'Hourly Salary must be a number between 0 and 2 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'Expected Work Hours Per Week',
		key: 'regular_hours',
		alternateMatches: ['expected work hours per week', 'Regular Hours'],
		fieldType: {
			type: 'input',
		},
		example: '37.5 or 30',
		validations: [
			{
				rule: 'regex',
				value: /^(100(\.00?)?|([1-9]?[0-9])(\.[0-9]{1,2})?)$/,
				errorMessage: 'Expected Work Hours Per Week must be a number',
				level: 'error',
			},
		],
	},
	{
		label: 'Is Director',
		key: 'is_director',
		alternateMatches: ['is director'],
		fieldType: {
			type: 'input',
		},
		example: 'Yes or No',
		validations: [
			{
				rule: 'required',
				errorMessage: 'Is Director is required',
			},
			{
				rule: 'regex',
				value: /^(?:Yes|No|yes|no)$/,
				errorMessage: 'Is Director must be Yes or No',
			},
		],
	},
	{
		label: 'Date Became Director',
		key: 'date_became_director',
		alternateMatches: ['date became director', 'Date Became Director'],
		fieldType: {
			type: 'input',
		},
		example: '2024-01-21',
		validations: [
			{
				rule: 'regex',
				value: /^(?:(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])|([0-2]?\d|3[01])\/(0[1-9]|1[0-2])\/\d{4})|^$/,
				errorMessage: 'Date Became Director must be valid. Format: yyyy-mm-dd or dd/mm/yyyy',
				level: 'error',
			},
		],
	},
	{
		label: 'Is Using Alternate NI Calculation',
		key: 'director_ni_type',
		alternateMatches: ['Is Using Alternate NI Calculation', 'Director NI Type'],
		fieldType: {
			type: 'input',
		},
		example: 'Yes or No',
		validations: [
			{
				rule: 'regex',
				value: /^(Yes|No|yes|no)?$/,
				errorMessage: 'Is Using Alternate NI Calculation must be Yes or No',
			},
		],
	},
	{
		label: 'Starter Type',
		key: 'starter_type',
		alternateMatches: ['starter type', 'Starter Type'],
		fieldType: {
			type: 'select',
			options: [
				{ value: 'New employee with P45', label: 'New employee with P45' },
				{
					value: 'New employee without P45',
					label: 'New employee without P45',
				},
				{ value: 'Existing employee', label: 'Existing employee' },
			],
			defaultValue: '',
		},
		validations: [
			{
				rule: 'required',
				errorMessage: 'Starter Type is required',
				level: 'error',
			},
		],
		example: 'New employee with P45',
	},
	{
		label: 'Starter Declaration',
		key: 'starter_declaration',
		alternateMatches: ['starter declaration'],
		fieldType: {
			type: 'input',
		},
		example: 'A',
		validations: [
			{
				rule: 'required',
				errorMessage: 'Starter Declaration is required',
			},
			{
				rule: 'regex',
				value: /^([ABCabc])$/,
				errorMessage: 'Starter Declaration must be A, B or C',
			},
		],
	},
	{
		label: 'Tax Code',
		key: 'tax_code',
		alternateMatches: ['tax code'],
		fieldType: {
			type: 'input',
		},
		example: '1257L',
		validations: [
			{
				rule: 'required',
				errorMessage: 'Tax Code is required',
				level: 'error',
			},
			{
				rule: 'regex',
				value:
					/^(?:[CS])?(([1-9][0-9]{0,5}[LMNPTY])|(BR)|(0T)|(NT)|(D[0-8])|([K][1-9][0-9]{0,5}))$/,
				errorMessage: 'Tax code is invalid',
				level: 'error',
			},
		],
	},
	{
		label: 'Is W1/M1 Basis ?',
		key: 'is_w1m1',
		alternateMatches: ['Is W1/M1 Basis ?'],
		fieldType: {
			type: 'input',
		},
		example: 'Yes or No',
		validations: [
			{
				rule: 'required',
				errorMessage: 'This is a required field',
				level: 'error',
			},
			{
				rule: 'regex',
				value: /^(Yes|No|yes|no)$/,
				errorMessage: 'Is W1/M1 Basis must be Yes or No',
				level: 'error',
			},
		],
	},
	{
		label: 'Previous Employment Gross Pay',
		key: 'previous_employment_gross_pay',
		alternateMatches: [
			'previous employment gross pay',
			'Previous Employment Gross Pay',
		],
		fieldType: {
			type: 'input',
		},
		example: '',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'Previous Employment Gross Pay must be a number between 0 and 300 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'Previous Employment Tax',
		key: 'previous_employment_tax',
		alternateMatches: ['previous employment tax', 'Previous Employment Tax'],
		fieldType: {
			type: 'input',
		},
		example: '',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'Previous Employment Tax must be a number between 0 and 300 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'Gross for Tax YTD',
		key: 'gross_for_tax_ytd',
		alternateMatches: ['gross for tax ytd', 'Gross for Tax YTD'],
		fieldType: {
			type: 'input',
		},
		example: '',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'Gross for Tax YTD must be a number between 0 and 300 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'Tax Paid YTD',
		key: 'tax_paid_ytd',
		alternateMatches: ['Tax Paid YTD', 'tax paid ytd'],
		fieldType: {
			type: 'input',
		},
		example: '',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'Tax Paid YTD must be a number between 0 and 300 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'Gross For NI YTD',
		key: 'gross_for_ni_ytd',
		alternateMatches: ['Gross For NI YTD', 'gross for ni ytd'],
		fieldType: {
			type: 'input',
		},
		example: '',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'Gross For NI YTD must be a number between 0 and 300 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'Earnings at LEL YTD',
		key: 'earnings_at_lel_ytd',
		alternateMatches: ['Earnings at LEL YTD', 'earnings at lel ytd'],
		fieldType: {
			type: 'input',
		},
		example: '',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'Earnings at LEL YTD must be a number between 0 and 300 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'Earnings at PT YTD',
		key: 'earnings_at_pt_ytd',
		alternateMatches: ['Earnings at PT YTD', 'earnings at pt ytd'],
		fieldType: {
			type: 'input',
		},
		example: '',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'Earnings at PT YTD must be a number between 0 and 300 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'Earnings at UEL YTD',
		key: 'earnings_at_uel_ytd',
		alternateMatches: ['Earnings at UEL YTD', 'earnings at uel ytd'],
		fieldType: {
			type: 'input',
		},
		example: '',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'Earnings at UEL YTD must be a number between 0 and 300 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'Employee NIC YTD',
		key: 'employee_nic_ytd',
		alternateMatches: ['Employee NIC YTD', 'employee nic ytd'],
		fieldType: {
			type: 'input',
		},
		example: '',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'Employee NIC YTD must be a number between 0 and 300 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'Employer NIC YTD',
		key: 'employer_nic_ytd',
		alternateMatches: ['Employer NIC YTD', 'employer nic ytd'],
		fieldType: {
			type: 'input',
		},
		example: '',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'Employer NIC YTD must be a number between 0 and 300 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'SSP YTD',
		key: 'ssp_ytd',
		alternateMatches: ['SSP YTD', 'ssp ytd'],
		fieldType: {
			type: 'input',
		},
		example: '',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'SSP YTD must be a number between 0 and 300 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'SMP YTD',
		key: 'smp_ytd',
		alternateMatches: ['SMP YTD', 'smp ytd'],
		fieldType: {
			type: 'input',
		},
		example: '',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'SMP YTD must be a number between 0 and 300 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'SPP YTD',
		key: 'spp_ytd',
		alternateMatches: ['SPP YTD', 'spp ytd'],
		fieldType: {
			type: 'input',
		},
		example: '',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'SPP YTD must be a number between 0 and 300 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'SAP YTD',
		key: 'sap_ytd',
		alternateMatches: ['SAP YTD', 'sap ytd'],
		fieldType: {
			type: 'input',
		},
		example: '',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'SAP YTD must be a number between 0 and 300 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'SPBP YTD',
		key: 'spbp_ytd',
		alternateMatches: ['SPBP YTD', 'spbp ytd'],
		fieldType: {
			type: 'input',
		},
		example: '',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'SPBP YTD must be a number between 0 and 300 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'ShPP YTD',
		key: 'shpp_ytd',
		alternateMatches: ['ShPP YTD', 'shpp ytd'],
		fieldType: {
			type: 'input',
		},
		example: '',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'ShPP YTD must be a number between 0 and 300 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'Student Loan Deductions YTD',
		key: 'student_loan_deductions_ytd',
		alternateMatches: [
			'student loan ytd',
			'Student Loan YTD',
			'student loan deductions ytd',
			'Student Loan Deductions YTD',
		],
		fieldType: {
			type: 'input',
		},
		example: '2',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'Student Loan Deductions YTD must be a number between 0 and 300 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'Postgraduate Loan Deductions YTD',
		key: 'postgraduate_loan_deductions_ytd',
		alternateMatches: [
			'postgraduate loan ytd',
			'Postgraduate Loan YTD',
			'postgraduate loan deductions ytd',
			'Postgraduate Loan Deductions YTD',
		],
		fieldType: {
			type: 'input',
		},
		example: 'Yes',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'Postgraduate Loan Deductions YTD must be a number between 0 and 300 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'Employee Pension Under Net Pay YTD',
		key: 'employee_net_pay_pension_ytd',
		alternateMatches: ['Employee Pension Under Net Pay YTD'],
		fieldType: {
			type: 'input',
		},
		example: '',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'Employee Pension Under Net Pay YTD must be a number between 0 and 300 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'Employee Pension YTD',
		key: 'employee_pension_ytd',
		alternateMatches: ['Employee Pension YTD', 'employee pension ytd'],
		fieldType: {
			type: 'input',
		},
		example: '',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'Employee Pension YTD must be a number between 0 and 300 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'Employer Pension YTD',
		key: 'employer_pension_ytd',
		alternateMatches: ['Employer Pension YTD', 'employer pension ytd'],
		fieldType: {
			type: 'input',
		},
		example: '',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'Employer Pension YTD must be a number between 0 and 300 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'Payrolled Benefits YTD',
		key: 'payrolled_benefits_ytd',
		alternateMatches: ['payrolled benefits ytd', 'Payrolled Benefits YTD'],
		fieldType: {
			type: 'input',
		},
		example: '',
		validations: [
			{
				rule: 'regex',
				value: /^(?:0|[1-9]\d{0,4}|[1-2]\d{5}|300000)(?:\.\d{1,2})?$|^$/,
				errorMessage:
					'Payrolled Benefits YTD must be a number between 0 and 300 000. For decimals, we only accept two decimal places, and you muse a dot.',
				level: 'error',
			},
		],
	},
	{
		label: 'Bank Sort Code',
		key: 'bank_sort_code',
		alternateMatches: ['bank sort code', 'Bank Sort Code'],
		fieldType: {
			type: 'input',
		},
		example: '121212',
	},
	{
		label: 'Bank Account Number',
		key: 'bank_account_number',
		alternateMatches: ['bank account number', 'Bank Account Number'],
		fieldType: {
			type: 'input',
		},
		example: '12341234',
	},
	{
		label: 'Bank Account Name',
		key: 'bank_account_name',
		alternateMatches: ['bank account name', 'Bank Account Name'],
		fieldType: {
			type: 'input',
		},
		example: 'Demo Account',
	},
	{
		label: 'Bank Name',
		key: 'bank_name',
		alternateMatches: ['bank name', 'Bank Name'],
		fieldType: {
			type: 'input',
		},
		example: 'Barclays',
	},
];

const BulkEmployeesCsv = ({
	openCsv = false,
	setOpenCsv = () => {},
	employeesEmail = [],
	employeePayroll = [],
	isOnboarding = false,
	employer = {},
}) => {
	const validateRow = (rowData, addError, allData) => {
		if (employeePayroll.includes(rowData?.payroll_id)) {
			addError('payroll_id', {
				message: 'Payroll ID must be unique per employee',
				level: 'error',
			});
		}
		if (employeesEmail.includes(rowData?.email)) {
			addError('email', {
				message: 'Email must be unique per employee',
				level: 'error',
			});
		}

		if (
			employer?.pay_schedules?.filter(
				(schedule) => schedule.name === rowData?.pay_schedule,
			).length === 0
		) {
			addError('pay_schedule', {
				message: 'Pay Schedule must match the name of a pay schedule.',
				level: 'error',
			});
		}

		/*
			Banking Columns Validation
		 */
		if (
			rowData.bank_sort_code?.length > 0 ||
			rowData.bank_account_name?.length > 0 ||
			rowData.bank_name?.length > 0 ||
			rowData.bank_account_number?.length > 0
		) {
			if (
				rowData?.bank_account_number?.length === 0 ||
				rowData.bank_account_number === undefined
			) {
				addError('bank_account_number', {
					message:
						'If at least one banking information is filled, you need to fill all or ' +
						'delete the column filled.',
					level: 'error',
				});
			}
			if (
				rowData?.bank_account_name?.length === 0 ||
				rowData.bank_account_name === undefined
			) {
				addError('bank_account_name', {
					message:
						'If at least one banking information is filled, you need to fill all or ' +
						'delete the column filled.',
					level: 'error',
				});
			}
			if (rowData?.bank_name?.length === 0 || !rowData.bank_name) {
				addError('bank_name', {
					message:
						'If at least one banking information is filled, you need to fill all or ' +
						'delete the column filled.',
					level: 'error',
				});
			}
			if (rowData?.bank_sort_code?.length === 0 || !rowData.bank_sort_code) {
				addError('bank_sort_code', {
					message:
						'If at least one banking information is filled, you need to fill all or ' +
						'delete the column filled.',
					level: 'error',
				});
			}
		}

		/*
			Director Columns Validation
		 */
		if (
			rowData?.is_director === 'yes' ||
			rowData?.is_director === 'Yes' ||
			rowData?.date_became_director ||
			rowData?.director_ni_type
		) {
			if (
				rowData?.date_became_director?.length === 0 ||
				!rowData?.date_became_director
			) {
				addError('date_became_director', {
					message: 'This field must be filled, if the employee is a director.',
					level: 'error',
				});
			}

			if (
				rowData?.director_ni_type?.length === 0 ||
				!rowData?.director_ni_type
			) {
				addError('director_ni_type', {
					message: 'This field must be filled, if the employee is a director.',
					level: 'error',
				});
			}

			if (
				(rowData?.date_became_director || rowData?.director_ni_type) &&
				rowData?.is_director !== 'Yes' &&
				rowData?.is_director !== 'yes'
			) {
				addError('is_director', {
					message:
						'This field must be Yes, if the other fields for director are not empty.',
					level: 'error',
				});
			}
		}

		/*
			Salary Mandatory
		 */
		if (
			(rowData?.annual_salary === '' &&
				(!rowData?.hourly_salary || rowData?.hourly_salary === '')) ||
			(!rowData?.annual_salary &&
				(!rowData?.hourly_salary || rowData?.hourly_salary === '')) ||
			(!rowData?.hourly_salary &&
				(!rowData?.annual_salary || rowData?.annual_salary === '')) ||
			(rowData?.hourly_salary === '' &&
				(!rowData?.annual_salary || rowData?.annual_salary === ''))
		) {
			addError('annual_salary', {
				message: 'You must provide either the Annual Salary or Hourly Salary',
				level: 'error',
			});
			addError('hourly_salary', {
				message: 'You must provide either the Annual Salary or Hourly Salary',
				level: 'error',
			});
		}

		/*
			Only One Salary Type
		 */
		if (rowData?.annual_salary && rowData?.hourly_salary) {
			addError('annual_salary', {
				message: 'You must provide either the Annual Salary or Hourly Salary',
				level: 'error',
			});
			addError('hourly_salary', {
				message: 'You must provide either the Annual Salary or Hourly Salary',
				level: 'error',
			});
		}

		if (rowData?.ni_number?.length > 0) {
			const existingNI = allData.filter(
				(data) => data.ni_number === rowData.ni_number,
			);
			if (existingNI.length > 1) {
				addError('ni_number', {
					message: 'Ni number must be unique',
					level: 'error',
				});
			}
		}
		return rowData;
	};
	const { mutate: bulkImportCsv } = useBulkImportCsv(isOnboarding);

	return (
		<div
			id={'main-page'}
			style={{
				display: 'flex',
				justifyContent: 'center',
				flexWrap: 'wrap',
			}}
		>
			<ReactSpreadsheetImport
				id={'react-spreadsheet'}
				isOpen={openCsv}
				onClose={() => setOpenCsv(false)}
				fields={fields}
				rowHook={(data, addError, allData) =>
					validateRow(data, addError, allData)
				}
				allowInvalidSubmit={false}
				autoMapSelectValues={true}
				onSubmit={(data) => {
					bulkImportCsv({
						employees: {
							uploadedData: data.validData,
						},
					});
				}}
				customTheme={{
					components: {
						Button: {
							baseStyle: {
								borderRadius: 'none',
							},
							defaultProps: {
								colorScheme: 'yellow',
							},
						},
					},
				}}
			/>
		</div>
	);
};

export default BulkEmployeesCsv;
