import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Divider,
	FreepayrollButton,
	Radio,
	Typography,
} from '@collegia-partners/ui-kit';
import { useCloseTaxYear } from '../../hooks/employer';
import { StickerAlert } from '../../components/StickerAlert';
import { keyframes } from '@emotion/react';
import { Box } from '@mui/material';

function CloseTaxYearWarning({ onCancel = () => {}, onContinue = () => {} }) {
	const PageText = [
		{
			title: 'P60 Distribution:',
			subTitle:
				'We will generate and send P60 forms to all employees. These forms summarise the total pay, ' +
				'tax, and National Insurance contributions for the entire tax year. You will also be able to view ' +
				'their P60s directly in their profiles within the FreePayroll system.',
		},
		{
			title: 'Final EPS Submission:',
			subTitle:
				'We will submit the final Employer Payment Summary (EPS) to HMRC. This submission informs HMRC ' +
				'that all payroll activities for the current tax year are complete, and it includes any adjustments ' +
				'such as statutory payments or recoverable amounts.',
		},
	];

	const warningText =
		'If you need to make any corrections to payments made during this tax year, you will need' +
		'to do so outside of FreePayroll, as we do not allow the rollback of closed tax years.' +
		'Before closing the tax year, please ensure all pay schedules have been completed and any ' +
		'necessary adjustments have been made. Once the tax year is closed, no further amendments' +
		'can be made within FreePayroll.';

	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '2vw' }}>
			<Typography variant={'title'}>
				Closing Tax Year is Irreversible
			</Typography>
			<Typography variant={'subtitle2'} weight={'normal'} color={'gray'}>
				You are about to close the current tax year. Please note that this
				action is irreversible, and once completed, no further changes can be
				made within FreePayroll. Here’s what will happen:
			</Typography>
			<Divider color={'light-gray'} />
			{PageText.map((item, index) => (
				<div key={index}>
					<Typography variant={'subtitle'} color={'black'}>
						{item.title}
					</Typography>
					<Typography variant={'body'}>{item.subTitle}</Typography>
				</div>
			))}
			<StickerAlert text={warningText} stickerColor={'#0C1D3A'} mode={'info'} />
			<div style={{ display: 'flex', maxWidth: '40%', gap: '0.83vw' }}>
				<FreepayrollButton variant={'white-button'} onClick={onCancel}>
					Cancel
				</FreepayrollButton>
				<FreepayrollButton variant={'primary'} onClick={onContinue}>
					Continue
				</FreepayrollButton>
			</div>
		</div>
	);
}

function CarryOver({ onCancel = () => {} }) {
	const { mutate: closeTaxYear, isPending: isClosingTaxYear } =
		useCloseTaxYear();
	const [choice, setChoice] = useState('');

	const Options = [
		{
			value: 'Carry Over',
			description: {
				text:
					'If you choose to carry over your current pay schedules, all existing schedules will be ' +
					'automatically moved to the new tax year, ensuring continuity without the need for additional setup.',
			},
		},
		{
			value: 'Inactivate and Create New',
			description: {
				text:
					'If you prefer to start fresh, you can inactivate the existing pay schedules ' +
					'and set up new ones tailored to your specific needs for the new tax year.',
			},
		},
	];

	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '2vw' }}>
			<Typography variant={'title'}>Carry Over Pay Schedules</Typography>
			<Typography variant={'subtitle2'} weight={'normal'} color={'gray'}>
				As you prepare to start the new tax year, you have the option to either
				carry over your existing pay schedules or inactivate them and create new
				ones.
			</Typography>
			<Divider color={'light-gray'} />
			<Typography variant={'subtitle2'} color={'black'}>
				Please select one of the following options:
			</Typography>
			<div style={{ maxWidth: '60%' }}>
				<Radio
					options={Options}
					onOptionChange={(value) => {
						setChoice(
							value === 'Carry Over' ? 'carry_over' : 'delete_create_new',
						);
					}}
				/>
			</div>
			<div style={{ display: 'flex', maxWidth: '40%', gap: '0.83vw' }}>
				<FreepayrollButton
					variant={'white-button'}
					onClick={onCancel}
					isLoading={isClosingTaxYear}
				>
					Cancel
				</FreepayrollButton>
				<FreepayrollButton
					variant={'primary'}
					onClick={() => {
						closeTaxYear({
							employers: {
								choice: choice,
							},
						});
					}}
					isLoading={isClosingTaxYear}
				>
					Close Tax Year
				</FreepayrollButton>
			</div>
		</div>
	);
}

function LoadingCloseYear() {
	const actions = [
		'Carrying over pay schedules...',
		'Generating P60s...',
		'Submitting final EPS...',
		'Closing tax year...',
	];

	const blink = keyframes`
		  0%, 100% {
		    opacity: 1;
		  }
		  50% {
		    opacity: 0;
		  }
		`;

	// Define a keyframes animation for a fade-in and slide-up effect.
	const fadeInUp = keyframes`
		  from {
		    opacity: 0;
		    transform: translateY(20px);
		  }
		  to {
		    opacity: 1;
		    transform: translateY(0);
		  }
		`;

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '2rem',
				padding: '2rem',
			}}
		>
			<Typography variant="title">Processing Year-End Tasks</Typography>

			<Box width="100%" maxWidth="500px" mb={3}>
				{actions.map((action, index) => (
					<Box
						key={index}
						display="flex"
						alignItems="center"
						gap={2}
						mb={2}
						sx={{
							opacity: 0,
							animation: `
			                    ${fadeInUp} 0.8s ease-out forwards,
			                    ${blink} 1.3s ease-in-out infinite
			                `,
							animationDelay: `0s, 0.8s`,
						}}
					>
						<Typography variant="subtitle" color="gray">
							{action}
						</Typography>
					</Box>
				))}
			</Box>
		</div>
	);
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const CloseTaxYear = (): JSX.Element => {
	const push = useNavigate(),
		[step, setStep] = useState(0);

	const { isPending } = useCloseTaxYear();

	return (
		<div id={'close-tax-year'}>
			{step === 0 && (
				<CloseTaxYearWarning
					onCancel={() => push('/main')}
					onContinue={() => setStep(1)}
				/>
			)}
			{step === 1 && <CarryOver onCancel={() => push('/main')} />}
			{isPending && <LoadingCloseYear />}
		</div>
	);
};

export default CloseTaxYear;
