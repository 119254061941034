import {
	useMutation,
	useQuery, useQueryClient,
} from '@tanstack/react-query';
import Request from "../utils/Request";
import {toast} from "sonner";
import {useNavigate} from "react-router-dom";

export function useGetEmployer({ relations = [] }) {
	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['employer', ...relations],
		queryFn: async () => {
			
			let queryString = '';
			
			if (relations.length) {
				// Create a string like 'relations[]=relation1&relations[]=relation2'
				queryString = relations.map(relation => `relations[]=${relation}`).join('&');
			}
			
			return await Request.get(`/api/employers/find` + (queryString ? `?${queryString}` : ''))
				.then(response => response.data?.employer)
		},
		keepPreviousData: true,
		staleTime: 1000 * 60 * 5, // 5 minutes
	});
	
	return {
		employer: data,
		isPending,
		isError,
	};
}

export function useCloseTaxYear() {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	
	return useMutation({
		mutationFn: async (data) => {
			return await Request.post('/api/employers/close-tax-year',data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: () => {
			toast.success('Tax year closed successfully');
			navigate('/main');
			queryClient.invalidateQueries({queryKey: ['payrolls']});
			queryClient.invalidateQueries({queryKey: ['employer']});
		},
	});
}

export function useUpdateEmployerDetails() {
	const queryClient = useQueryClient();
	
	return useMutation({
		mutationFn: async (data) =>
		{
			const formData = new FormData();
			formData.append("picture", data?.company_logo);
			delete data?.company_logo;
			formData.append("data", JSON.stringify(data));
			return await Request.post('/api/employers/update-profile', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					}
				})
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			toast.success('Employer details updated successfully');
			queryClient.setQueryData(['employer','address'], data?.data);
		},
	});
}

export function useUpdateDefaultPensionSettings() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post('/api/employers/update-pension-settings/', data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			toast.success('Employer pension settings updated successfully');
			queryClient.setQueryData(['employer', 'pension_scheme'], data?.data);
		},
	});
}