import {Modal} from "@collegia-partners/ui-kit";
import StyleTable from "../StyledComponents/StyleTable";


export const FpsErrorsModal = ({
	row = {
		fps_submission_error: "",
		fps_submitted: ""
	},
	isOpen = false,
	setIsOpen = () => {},
}) => {
	const columns = [
		{
			name: "name",
			label: "Employee",
		},
		{
			name: "message",
			label: "Error",
		}
	]
	
	// parser for the xml files
	const parser = new DOMParser();
	
	// parser fps errors xml file
	const xml = parser.parseFromString(row.fps_submission_error, "text/xml");
	
	// get <Error> tags
	const errorTags = xml.getElementsByTagName("Error");
	const employeeErrors = [];
	
	// regex to match Employee and get the array key
	const regex = /fps:Employee\[(\d+)]/;
	
	// loop on errorTags
	for (let error of errorTags) {
		const location = error.getElementsByTagName("Location")[0];
		const text = error.getElementsByTagName("Text")[0];
		
		if (location && text) {
			const match = location.textContent.match(regex);
			if (match) {
				employeeErrors.push({
					// employeeKey is indexed starting at 1
					employeeKey: Number(match[1]), // Convert key to a number
					message: text.textContent.trim() // Extract and trim error text
				});
			}
		}
	}
	
	// parse the submitted file to match the errors with the respective employee
	const xmlSubmitted = parser.parseFromString(row.fps_submitted, "text/xml");
	const employeeTags = xmlSubmitted.getElementsByTagName("Employee");
	
	// match the errorTags with the employee
	employeeErrors.forEach((item) => {
		// extract the employee name
		// employeeKey is indexed starting at 1
		const title = employeeTags[item.employeeKey - 1].getElementsByTagName("Ttl")[0].textContent;
		const forename = employeeTags[item.employeeKey - 1].getElementsByTagName("Fore")[0].textContent;
		const surname = employeeTags[item.employeeKey - 1].getElementsByTagName("Sur")[0].textContent;
		
		// Add the full name to the item
		item.name = `${title} ${forename} ${surname}`;
	});
	
	return (
		<Modal
			onClose={() => setIsOpen(false)}
			isOpen={isOpen}
		>
			<div>
				<StyleTable
					title={"FPS Submission Errors"}
					columns={columns}
					data={employeeErrors}
					className={"fps-errors-table"}
					options={{
						responsive: 'simple',
						serverSide: false,
					}}
				/>
			</div>
		</Modal>
	)
}