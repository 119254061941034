import {useMutation, useQueryClient} from "@tanstack/react-query";
import Request from "../../utils/Request";
import {useNavigate} from "react-router-dom";

export function useBasicSetup(setMandateFlowInitiated,setToken,setHasCollegiaAccount) {

	const queryClient = useQueryClient();
	const push = useNavigate();

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post(`/api/employers/basic-setup`,data)
				.then(response => response.data);
		},
		onError: (error) => {
			if (error.response.status === 422) {
				setHasCollegiaAccount(true);
			}
			return error;
		},
		onSuccess: (data) => {
			if (data?.step === 'automatic_enrolment') {
				setMandateFlowInitiated(true);
				setToken(data?.token);
			}
			queryClient.setQueryData(['employer','collegia_emandate'], data?.employer);
			queryClient.setQueryData(['employer','pay-schedules'], data?.employer);
			if (data?.step === 'compliance') {
				push('/onboarding/dashboard');
			}
			return data;
		},
	});
}