import React from "react";
import {FreepayrollButton, Icon, Typography} from "@collegia-partners/ui-kit";

type StickerAlertProps = {
	text: string;
	stickerColor?: string;
	action?: () => void;
	actionText?: string;
	iconColor?: 'white' | 'red' | 'blue';
	mode?: 'action' | 'info';
	subtitle?: string;
}

export const StickerAlert = ({
	text = '',
	stickerColor = '#000000',
	action,
	actionText,
	iconColor = 'white',
	mode = 'action',
	subtitle = '',
	learnMore = '',
} :StickerAlertProps) => {

	let style;

	if (mode === 'info') {
		style = {
			display: 'flex',
			flexDirection: 'column',
			background: stickerColor ,
			padding: '0.6rem 1.5rem',
			gap: '0.83vw'
		};
	} else {
		style = {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			background: stickerColor ,
			padding: '0.6rem 1.5rem',
		}
	}

	return (
		<div
			style={style}
		>
			<div style={{display: 'flex', alignItems: 'center', gap: '1vw'}}>
				{
					iconColor === 'white' && (
						<Icon iconName={'white-and-black-warning'}/>
					)
				}
				{
					iconColor === 'red' && (
						<Icon iconName={'error'}/>
					)
				}
				{
					iconColor === 'blue' && (
						<Icon iconName={'blue-and-white-warning'}/>
					)
				}
				<Typography
					variant={"body"}
					color={"white"}
					size={"medium"}
					weight={"bold"}
				>
					{text}
				</Typography>
			</div>
			{
				mode === 'action' && (
					<FreepayrollButton
						variant={"blue-with-radius"}
						onClick={action}
						size={"small"}
						custom={true}
						height={0.5}
						width={1.5}
					>
						{actionText}
					</FreepayrollButton>
				)
			}
			{
				mode === 'info' && subtitle !== '' && (
					<div >
						<Typography
							variant={"body"}
							color={"white"}
							size={"small"}
						>
							{subtitle}
							{' '}
							{
								learnMore !== '' &&
								<a href={learnMore} style={{color: 'white', textDecoration: 'underline', fontWeight: 'bold'}} target={'_blank'} rel={'noreferrer'}>
									Find out more here
								</a>
							}
							{
								learnMore !== '' && '.'
							}
						</Typography>
					</div>

				)
			}
		</div>
	);
}