import {
	useMutation,
	useQuery, useQueryClient,
} from '@tanstack/react-query';
import Request from "../utils/Request";
import {toast} from "sonner";

export function useGetHmrcSettings() {
	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['hmrc-settings'],
		queryFn: async () => {
			return await Request.get('/api/employers/hmrc-settings/get')
				.then(response => response.data)
		},
		keepPreviousData: false,
		staleTime: 1000 * 10 * 60  , //60 minutes
	});

	return {
		hmrcSettings: data?.data,
		isPending,
		isError,
	};
}

export function useUpdateHmrcSettings({ onSuccess = () => {} } = {}) {
	const queryClient = useQueryClient();
	
	return useMutation({
		mutationFn: async (data) => {
			return await Request.post('/api/employers/hmrc-settings/update', data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			queryClient.setQueryData(['hmrc-settings'], data);
			onSuccess();
		},
	});
}

export function useTestHmrcConnection() {

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post('/api/employers/hmrc-settings/test-connection', data)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			if (data?.hmrc_connected) {
				toast.success('HMRC connection successful');
			} else {
				toast.error('HMRC connection failed');
			}
		},
	});
}