import {useMutation, useQueryClient} from "@tanstack/react-query";
import Request from "../../utils/Request";
import {toast} from "sonner";
import {useNavigate} from "react-router-dom";

export function useBulkImportCsv(isOnboarding) {

	const queryClient = useQueryClient();
	const push = useNavigate();

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post(`/api/employees/bulk-import-csv`,data)
				.then(response => {
					return response;
				});
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['employees'] });
			queryClient.refetchQueries({ queryKey: ['employer'] });
			queryClient.removeQueries({ queryKey: ['me'] });
			if (!isOnboarding) {
				push('/main/people/team-members');
			} else {
				push('/onboarding/add-employee-method');
			}
			toast.success('Employees imported successfully');
		},
	});
}