import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Tab,
	Tabs,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { StepZero } from '../../../components/Pages/BasicSetup/StepZero/StepZero';
import { StepOne } from '../../../components/Pages/BasicSetup/StepOne/StepOne';
import { StepTwo } from '../../../components/Pages/BasicSetup/StepTwo/StepTwo';
import { StyleAppBar } from '../../../components/StyleAppBar';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { parseISO } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';
import collegiaLogo from '../../../images/svg/Logos/Logo-collegia.svg';
import StyleTypography from '../../../components/StyledComponents/StyleTypography';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import StepThreeCompliance from '../../../components/Pages/BasicSetup/StepThree/StepThreeCompliance';
import DDLogo from '../../../images/svg/direct-debit-1.svg';
import StyleButton from '../../../components/StyledComponents/StyleButton';
import Request from '../../../utils/Request';
import { useUser } from '../../../context/UserContext';
import { useBasicSetup } from '../../../hooks/employer/useBasicSetup';
import { useGetEmployer } from '../../../hooks/employer';
import { useRetrieveMandateToken } from '../../../hooks/employer/useRetrieveMandateToken';
import { useImportCollegiaData } from '../../../hooks/employer/useImportCollegiaData';
import { FreepayrollButton } from '@collegia-partners/ui-kit';

function _renderSteps(
	step,
	stepZero,
	setStepZero,
	stepOne,
	setStepOne,
	stepTwo,
	setStepTwo,
	stepThree,
	setStepThree,
	setTabIndex,
	basicSetup,
	user,
) {
	switch (step) {
		case 0:
			return (
				<StepZero
					stepZero={stepZero}
					setStepZero={setStepZero}
					basicSetup={basicSetup}
					user={user}
				/>
			);
		case 1:
			return (
				<StepOne
					stepOne={stepOne}
					setStepOne={setStepOne}
					basicSetup={basicSetup}
				/>
			);
		case 2:
			return (
				<StepTwo
					stepTwo={stepTwo}
					setStepTwo={setStepTwo}
					setTabIndex={setTabIndex}
					basicSetup={basicSetup}
				/>
			);
		case 3:
			return (
				<StepThreeCompliance
					stepThree={stepThree}
					setStepThree={setStepThree}
					basicSetup={basicSetup}
				/>
			);
		default:
			return;
	}
}

function backLink(
	tabIndex,
	stepZero,
	stepOne,
	stepTwo,
	stepThree,
	setStepZero,
	setStepOne,
	setStepTwo,
	setStepThree,
	navigate,
) {
	switch (tabIndex) {
		case 0:
			if (stepZero.inner_step === 0) {
				return navigate('/onboarding/dashboard');
			} else {
				return setStepZero({
					...stepZero,
					inner_step: stepZero.inner_step - 1,
				});
			}
		case 1:
			if (stepOne.inner_step === 0) {
				return navigate('/onboarding/dashboard');
			} else {
				return setStepOne({ ...stepOne, inner_step: stepOne.inner_step - 1 });
			}
		case 2:
			if (stepTwo.inner_step === 0) {
				return navigate('/onboarding/dashboard');
			} else {
				return setStepTwo({ ...stepTwo, inner_step: stepTwo.inner_step - 1 });
			}
		case 3:
			if (stepThree.inner_step === 0) {
				return navigate('/onboarding/dashboard');
			} else {
				return setStepThree({
					...stepThree,
					inner_step: stepThree.inner_step - 1,
				});
			}
		default:
			return;
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const BasicSetup = () => {
	const { employer } = useGetEmployer({ relations: ['collegia_emandate'] });
	const { profileData: user, isPending: isUserLoading } = useUser();

	const [mandateFlowInitiated, setMandateFlowInitiated] = useState(false);
	const [token, setToken] = useState('');
	const [tokenLoading, setTokenLoading] = useState(false);
	const [hasCollegiaAccount, setHasCollegiaAccount] = useState(false);
	const [openCollegia, setOpenCollegia] = useState(false),
		[collegia, setCollegia] = useState({
			email: '',
			password: '',
		});

	const { mutate: basicSetup, isPending: isBasicLoading } = useBasicSetup(
		setMandateFlowInitiated,
		setToken,
		setHasCollegiaAccount,
	);

	const { mutate: importCollegiaData, isPending: isImportingCollegia } =
		useImportCollegiaData(setMandateFlowInitiated, setToken,setOpenCollegia);

	const { mandate } = useRetrieveMandateToken(tokenLoading);

	const [tabIndex, setTabIndex] = useState(0),
		[stepZero, setStepZero] = useState({
			myself: false,
			employees: false,
			not_paying: false,
			starting_tax_year: null,
			first_tax_year_pay: null,
			first_payroll_ever: null,
			current_payroll_provider: ' ',
			inner_step: 0,
			smp_recovered_ytd: 0,
			smp_nic_ytd: 0,
			spp_recovered_ytd: 0,
			spp_nic_ytd: 0,
			sap_recovered_ytd: 0,
			sap_nic_ytd: 0,
			shpp_recovered_ytd: 0,
			shpp_nic_ytd: 0,
			spbp_recovered_ytd: 0,
			spbp_nic_ytd: 0,
		}),
		[stepOne, setStepOne] = useState({
			accounts_office_reference: '',
			employer_paye_reference: '',
			unique_taxpayer_reference: '',
			employment_allowance: false,
			small_employer_relief: false,
			agriculture_sector: false,
			aquaculture_sector: false,
			road_transport_sector: false,
			other_sector: false,
			rti_user_id: '',
			rti_password: '',
			inner_step: 0,
			employment_allowance_claimed: 0
		}),
		[stepTwo, setStepTwo] = useState({
			has_pension_scheme: null,
			pension_selection: '',
			current_pension_provider: ' ',
			other_pension_provider: '',
			current_employer_id: '',
			pension_collection_date: ' ',
			paid_version: false,
			staging_date: employer?.staging_date
				? parseISO(employer?.staging_date)
				: null,
			pension_direct_debit_details: {
				account_holder_name: '',
				account_number: '',
				sort_code: '',
				phone_info: {
					phone: '',
					info: '',
				},
				email: '',
				postcode: '',
				address_line_1: '',
				address_line_2: '',
				address_line_3: '',
				country: '',
			},
			inner_step: 0,
		}),
		[stepThree, setStepThree] = useState({
			has_employer_liability_insurance: false,
			insurance_provider: '',
			insurance_policy_number: '',
			inner_step: 0,
		}),
		[open, setOpen] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		if (mandate?.id) {
			setMandateFlowInitiated(false);
			setTokenLoading(false);
		}
	}, [mandate]);

	useEffect(() => {
		if (
			employer?.collegia_emandate &&
			employer?.collegia_emandate?.token_active
		) {
			setMandateFlowInitiated(true);
			setToken(employer?.collegia_emandate?.token);
		}
	}, [employer?.collegia_emandate]);

	useEffect(() => {
		setTabIndex(employer?.basic_setup_step ?? 0);
		setStepTwo((prevStepTwo) => ({
			...prevStepTwo,
			staging_date: employer?.staging_date
				? parseISO(employer?.staging_date)
				: null,
		}));
		window.scrollTo(0, 0);
	}, [employer?.basic_setup_step, employer?.staging_date]);

	useEffect(() => {
		if (token !== '') {
			setOpen(true);
		}
	}, [token]);

	useEffect(() => {
		if (hasCollegiaAccount) {
			setOpenCollegia(true);
		} else {
			setOpenCollegia(false);
		}
	}, [hasCollegiaAccount]);

	if (isBasicLoading || isUserLoading) {
		return <Loading />;
	}

	if (tokenLoading) {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					width: '100%',
				}}
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						marginTop: '10%',
					}}
				>
					<div style={{ marginBottom: '32px' }}>
						<StyleTypography
							fontSize={32}
							fontSizeMedium={24}
							fontWeight={'bold'}
						>
							If you already signed your mandate, please wait 10 seconds. If you
							are not
							<br />
							redirected automatically, please{' '}
							<a href={'/onboarding/basic-setup'}>click here.</a>
						</StyleTypography>
					</div>
					<StyleTypography
						fontSize={32}
						fontSizeMedium={24}
						fontWeight={'bold'}
						color={'red'}
					>
						If you prefer to go back and manually fill the direct debit details,
						please
						<span
							style={{
								cursor: 'pointer',
								marginLeft: '10px',
								textDecoration: 'underline',
							}}
							onClick={() => {
								Request.put('/api/employers/cancel-mandate-token')
									.then((response) => {
										if (response.data.success) {
											setTokenLoading(false);
											setToken('');
											setMandateFlowInitiated(false);
											setOpen(false);
										}
									})
									.catch((error) => {
										console.error(error);
									});
							}}
						>
							click here.
						</span>
					</StyleTypography>
				</div>
				<Loading />
			</div>
		);
	}

	if (mandateFlowInitiated && open && token !== '') {
		return (
			<Dialog
				open={open}
				fullWidth={true}
				maxWidth={'md'}
				id={'direct-debit-guarantee-dialog'}
			>
				<DialogTitle>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<div>
							<Typography className={'dialog-title'}>
								We need to set up a Direct Debit to <br />
								collect pension contributions.
							</Typography>
						</div>
						<div>
							<img
								src={DDLogo}
								alt={'sign up with collegia'}
								className={'dd-logo'}
							/>
						</div>
					</div>
					<Typography className={'dialog-title-subtitle'}>
						Don't worry, FreePayroll is free, we will only collect pension
						contributions.
					</Typography>
				</DialogTitle>
				<DialogContent>
					<ul>{/* ... existing code ... */}</ul>
				</DialogContent>
				<DialogActions>
					<div>
						<StyleButton
							sx={{
								height: '3vw',
							}}
							isWhiteButton={true}
							fullWidth={false}
							onClick={() => {
								Request.put('/api/employers/cancel-mandate-token')
									.then((response) => {
										if (response.data.success) {
											setMandateFlowInitiated(false);
											setToken('');
											setTokenLoading(false);
											setOpen(false);
										}
									})
									.catch((error) => {
										console.error(error);
									});
							}}
						>
							Cancel
						</StyleButton>
					</div>
					<div>
						<StyleButton
							sx={{
								height: '3vw',
							}}
							fullWidth={false}
							onClick={() => {
								let link = '';
								if (process.env.REACT_APP_ENV === 'production') {
									link =
										'https://api.nuapay.com/tpp-ui/emandate-ui/redirect?conversationToken=' +
										token;
								} else {
									link =
										'https://sandbox.nuapay.com/tpp-ui/emandate-ui/redirect?conversationToken=' +
										token;
								}
								window.open(link, '_blank');
								setOpen(false);
								setToken('');
								setTokenLoading(true);
							}}
						>
							Continue
						</StyleButton>
					</div>
				</DialogActions>
			</Dialog>
		);
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
			}}
			id={'basic-setup'}
		>
			<StyleAppBar
				customOnClick={() =>
					backLink(
						tabIndex,
						stepZero,
						stepOne,
						stepTwo,
						stepThree,
						setStepZero,
						setStepOne,
						setStepTwo,
						setStepThree,
						navigate,
					)
				}
				custom={true}
			/>
			<div
				style={{
					width: '83.33%', // equivalente a 10/12 do grid original
				}}
			>
				<div
					style={{
						width: '100%',
					}}
				>
					<Tabs
						variant="fullWidth"
						className={'tabs-basic-setup'}
						value={tabIndex}
					>
						<Tab
							sx={{
								borderBottom:
									tabIndex > 0 ? '5px solid #0160FD' : '5px solid transparent',
							}}
							label={'Payroll Dates'}
							value={0}
						/>
						<Tab
							sx={{
								borderBottom:
									tabIndex > 1 ? '5px solid #0160FD' : '5px solid transparent',
							}}
							label={'HMRC Settings'}
							value={1}
						/>
						<Tab
							sx={{
								borderBottom:
									tabIndex > 2 ? '5px solid #0160FD' : '5px solid transparent',
							}}
							label={'Automatic Enrolment'}
							value={2}
						/>
						<Tab
							sx={{ borderBottom: '5px solid transparent' }}
							label={'Compliance'}
							value={3}
						/>
					</Tabs>
				</div>
				{_renderSteps(
					tabIndex,
					stepZero,
					setStepZero,
					stepOne,
					setStepOne,
					stepTwo,
					setStepTwo,
					stepThree,
					setStepThree,
					setTabIndex,
					basicSetup,
					user,
				)}
			</div>
			<Dialog
				onClose={() => setOpenCollegia(false)}
				open={openCollegia}
				fullWidth={true}
				maxWidth={'sm'}
				id={'signup-collegia'}
			>
				<div className={'dialog-close-button-container'}>
					<CloseIcon
						sx={{
							fontSize: '1.6vw',
						}}
						className={'dialog-close-button'}
						onClick={() => {
							setCollegia({
								email: '',
								password: '',
							});
							setOpenCollegia(false);
						}}
					/>
				</div>
				<div
					style={{
						width: '100%',
						textAlign: 'center',
					}}
				>
					<img
						src={collegiaLogo}
						alt={'sign up with collegia'}
						className={'collegia-logo'}
					/>
				</div>
				<DialogTitle>
					<StyleTypography
						fontSize={22}
						fontSizeMedium={22}
						fontWeight={'bold'}
						textAlign={'center'}
					>
						This company is already registered with Collegia.
					</StyleTypography>
					<StyleTypography
						fontSize={20}
						fontSizeMedium={20}
						color={'#000000'}
						textAlign={'center'}
					>
						Login with your Collegia account to continue:
					</StyleTypography>
				</DialogTitle>
				<DialogContent>
					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							gap: '16px',
						}}
					>
						<div style={{ width: '100%' }}>
							<InputLabel className={'default-input-label'}>Email*</InputLabel>
							<TextField
								className={'default-text-field'}
								fullWidth
								required
								value={collegia.email}
								onChange={(e) =>
									setCollegia({
										...collegia,
										email: e.target.value,
									})
								}
							/>
						</div>

						<div style={{ width: '100%' }}>
							<InputLabel className={'default-input-label'}>
								Password*
							</InputLabel>
							<TextField
								className={'default-text-field'}
								fullWidth
								required
								type={'password'}
								value={collegia.password}
								onChange={(e) =>
									setCollegia({
										...collegia,
										password: e.target.value,
									})
								}
							/>
						</div>
					</div>
				</DialogContent>
				<DialogActions>
					<div
						style={{
							width: '83.33%',
							display: 'flex',
							justifyContent: 'center',
							gap: '16px',
						}}
					>
						<div
							style={{
								width: '100%',
							}}
						>
							<FreepayrollButton
								onClick={() => {
									importCollegiaData({
										employers: {
											...collegia,
										},
									});
								}}
								variant={'primary'}
								isLoading={isImportingCollegia}
							>
								Login
							</FreepayrollButton>
						</div>
					</div>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default BasicSetup;
