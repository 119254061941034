import { z } from "zod";
import {
	Divider, FreepayrollButton, FreepayrollTextField, Typography,
} from "@collegia-partners/ui-kit";
import React, { useCallback, useEffect, useState } from "react";
import {useParams} from "react-router-dom";
import {validDataToSchema} from "../../../../../utils/Helpers";
import {useUpdateEmployee} from "../../../../../hooks/employees";
import {useGetEmployee} from "../../../../../hooks/employee/useGetEmployee";
import {useUser} from "../../../../../context/UserContext";
import {toast} from "sonner";

const yearToDateSchema = z.object({

	previousPayToDate: z.string({ message: "Required" }).min(1, "Required"),
	previousTaxToDate: z.string({ message: "Required" }).min(1, "Required"),

	grossForTax: z.string({ message: "Required" }).min(1, "Required"),
	taxDeducted: z.string({ message: "Required" }).min(1, "Required"),
	studentLoan: z.string({ message: "Required" }).min(1, "Required"),
	postgraduateLoan: z.string({ message: "Required" }).min(1, "Required"),
	employeePension: z.string({ message: "Required" }).min(1, "Required"),
	employerPension: z.string({ message: "Required" }).min(1, "Required"),

	benefitInKindPayrolledAmount: z.string({ message: "Required" }).min(1, "Required"),
	employeeNetPayPension: z.string({ message: "Required" }).min(1, "Required"),

	statutoryMaternityPay: z.string({ message: "Required" }).min(1, "Required"),
	statutoryPaternityPay: z.string({ message: "Required" }).min(1, "Required"),
	statutoryAdoptionPay: z.string({ message: "Required" }).min(1, "Required"),
	statutorySickPay: z.string({ message: "Required" }).min(1, "Required"),
	parentalBereavement: z.string({ message: "Required" }).min(1, "Required"),
	sharedParentalPay: z.string({ message: "Required" }).min(1, "Required"),
});

const categorySchema = z.object({
	nationalInsuranceCategory: z
		.string({ message: "Required" })
		.min(1, "Required"),
	earningsAtLeL: z.string({ message: "Required" }).min(1, "Required"),
	earningsToPT: z.string({ message: "Required" }).min(1, "Required"),
	earningsToUEL: z.string({ message: "Required" }).min(1, "Required"),
	employeeNationalInsurance: z
		.string({ message: "Required" })
		.min(1, "Required"),
	employerNationalInsurance: z
		.string({ message: "Required" })
		.min(1, "Required"),
	grossPayForNationalInsurance: z
		.string({ message: "Required" })
		.min(1, "Required"),

	directorEarningsAtLeL: z.string({ message: "Required" }).min(1, "Required"),
	directorEarningsToPT: z.string({ message: "Required" }).min(1, "Required"),
	directorEarningsToUEL: z.string({ message: "Required" }).min(1, "Required"),
	directorNationalInsurance: z
		.string({ message: "Required" })
		.min(1, "Required"),
	directorEmployerNationalInsurance: z
		.string({ message: "Required" })
		.min(1, "Required"),
	directorGrossPayForNationalInsurance: z
		.string({ message: "Required" })
		.min(1, "Required"),
});

const FieldGroup = ({ sectionTitle, items, data, errors, handleChange, isEditable, columns = 3 }) => {
	return (
		<div style={{ display: "flex", flexDirection: "column", gap: "0.8vw" }}>
			<Typography variant="subtitle" color="black" weight="bold">
				{sectionTitle}
			</Typography>
			<Divider color="light-gray" />
			<div
				style={{
					display: "grid",
					gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
					gap: "20px",
					alignItems: "flex-end",
				}}
			>
				{items.map((item, index) => (item?.show || item?.show === undefined) && (
					<FreepayrollTextField
						key={item.key}
						fieldType={item.type || "currency"}
						name={item.name}
						label={item.name}
						value={data[item.key]}
						index={index}
						onChange={(e) => handleChange({ e, key: item.key })}
						maxDecimalPlaces={item.maxDecimalPlaces ?? 2}
						error={!!errors[item.key]}
						helperText={errors[item.key]?.message}
						disabled={!isEditable}
					/>
				))}
			</div>
		</div>
	);
};

function NationalInsurance({ employee, categories, categoriesError, handleCategoryChange, isEditable }) {
	
	const items = [
		{
			name: "Gross pay for National Insurance",
			key: "grossPayForNationalInsurance",
		},
		{
			name: "Earnings at the Lower Earnings Limit (LEL)",
			key: "earningsAtLeL",
			maxDecimalPlaces: 0,
		},
		{
			name: "Earnings above the LEL, up to and including the Primary Threshold (PT)",
			key: "earningsToPT",
		},
		{
			name: "Earnings above the PT, up to and including the Upper Earnings Limit (UEL)",
			key: "earningsToUEL",
		},
		{
			name: "Employee's National Insurance",
			key: "employeeNationalInsurance",
		},
		{
			name: "Employer's National Insurance",
			key: "employerNationalInsurance",
		},
		{
			name: "Director's Earnings at LEL in the year to date",
			key: "directorEarningsAtLeL",
			show: employee?.is_director
		},
		{
			name: "Director's Earnings at PT in the year to date",
			key: "directorEarningsToPT",
			show: employee?.is_director
		},
		{
			name: "Director's Earnings at UEL in the year to date",
			key: "directorEarningsToUEL",
			show: employee?.is_director
		},
		{
			name: "Director's National Insurance in the year to date",
			key: "directorNationalInsurance",
			show: employee?.is_director
		},
		{
			name: "Director's Employer's National Insurance in the year to date",
			key: "directorEmployerNationalInsurance",
			show: employee?.is_director
		},
		{
			name: "Director's Gross pay for National Insurance in the year to date",
			key: "directorGrossPayForNationalInsurance",
			show: employee?.is_director
		},
	];

	return (
		<div style={{display: "flex", flexDirection: "column", gap: "1.8vw"}}>
			<Typography variant="subtitle" color="black" weight="bold">
				National Insurance Details
			</Typography>
			{categories.map((category, index) => (
				<FieldGroup
					key={index}
					sectionTitle={`Category ${category.nationalInsuranceCategory}`}
					items={items}
					data={category}
					errors={categoriesError[index] || {}}
					handleChange={(e) => handleCategoryChange({...e, index})}
					isEditable={isEditable}
					columns={3}
				/>
			))}
		</div>
	)
}

function PayrolledBenefitsAndPensions({data, errors, handleDataChange, isEditable}) {

	const items = [
		{name: "Payrolled benfits subject to PAYE tax", key: "benefitInKindPayrolledAmount"},
		{name: "Employee's pension contributions paid under net pay arrangement", key: "employeeNetPayPension"},
		{name: "Employee's pension contributions not paid under net pay arrangement", key: "employeePension"},
	];

	return (
		<FieldGroup
			sectionTitle={"Payrolled Benefits and Pensions"}
			items={items}
			data={data}
			errors={errors}
			handleChange={handleDataChange}
			isEditable={isEditable}
		/>
	)
}

function StatutoriesAndStudentLoans({ data, errors, handleDataChange, isEditable }) {
	
	const items = [
		{name: "Statutory maternity pay year to date", key: "statutoryMaternityPay"},
		{name: "Statutory paternity pay year to date", key: "statutoryPaternityPay"},
		{name: "Statutory adoption pay year to date", key: "statutoryAdoptionPay"},
		{name: "Statutory sick pay year to date", key: "statutorySickPay"},
		{name: "Parental bereavement pay year to date", key: "parentalBereavement"},
		{name: "Shared parental pay year to date", key: "sharedParentalPay"},
		{name: "Student loan deducted year to date", key: "studentLoan"},
		{name: "Postgraduate loan deducted year to date", key: "postgraduateLoan"},
	];
	
	return (
		<FieldGroup
			sectionTitle={"Statutory Payments and Student Loans"}
			items={items}
			data={data}
			errors={errors}
			handleChange={handleDataChange}
			isEditable={isEditable}
		/>
	)
}

function IncomeTax({ data, errors, handleDataChange, isEditable }) {
	
	const items = [
		{name: "Previous employment pay year to date", key: "previousPayToDate",},
		{name: "Previous employment tax to date", key: "previousTaxToDate"},
		{name: "Total pay to date in this employment", key: "grossForTax"},
		{name: "Total tax to date in this employment", key: "taxDeducted",},
	];

	return (
		<FieldGroup
			sectionTitle={"Income Tax"}
			items={items}
			data={data}
			errors={errors}
			handleChange={handleDataChange}
			isEditable={isEditable}
			columns={2}
		/>
	)
}

export default function YearToDatesTab() {

	const employeeId = useParams().employeeId;
	const {employee} = useGetEmployee({id: employeeId, relations: ['ytd_figure', 'active_ni_categories_ytd']});
	const {profileData: user} = useUser();
	const {mutateAsync: updateEmployee, isPending} = useUpdateEmployee();

	const isEditable = !employee?.fps_submitted || user.is_support_user;
	const [categoriesError, setCategoriesError] = useState([]);
	const [categories, setCategories] = useState([]);
	const [data, setData] = useState({});
	const [errors, setErrors] = useState({});

	const handleCategoryChange = useCallback(({e, option, index, key}) => {
		setCategoriesError((prev) => {
			if (!prev[index]) prev[index] = {};
			prev[index][key] = "";
			return prev;
		});

		setCategories((prev) => {
			const newCategories = [...prev];
			newCategories[index][key] = e ? e.target.value : option.value;
			return newCategories;
		});
	}, []);

	const handleDataChange = useCallback(({ e, key }) => {
		setErrors((prev) => {
			prev[key] = "";
			return prev;
		});
		setData((prev) => ({ ...prev, [key]: e ? e.target.value : e }));
	}, []);

	const onSubmit = useCallback(
		async (e) => {
			e?.preventDefault();

			const validDataResult = validDataToSchema(data, yearToDateSchema);

			if (!validDataResult.valid) {
				setErrors(validDataResult.error);
				toast.error("Please fill all required fields");
				window.scroll(0, 0);
				return;
			}

			for (let i = 0; i < categories.length; i++) {
				const category = categories[i];
				const validResult = validDataToSchema(category, categorySchema);
				if (validResult.valid) continue;

				setCategoriesError((prev) => {
					if (!prev[i]) prev[i] = {};
					prev[i] = validResult.error;
					return prev;
				});

				return;
			}
			
			await updateEmployee({
				safeId: employeeId,
				data: {
					employees: {
						'action': 'ytd_figures',
						'ytd_figures': {
							previous_employment_taxable_pay: data.previousPayToDate,
							previous_employment_tax_deducted: data.previousTaxToDate,
							gross_for_tax_ytd: data.grossForTax,
							tax_deducted_ytd: data.taxDeducted,
							student_loan_ytd: data.studentLoan,
							pg_loan_ytd: data.postgraduateLoan,
							employee_pension_ytd: data.employeePension,
							bik_payrolled_amount_ytd: data.benefitInKindPayrolledAmount,
							employee_net_pay_pension_ytd: data.employeeNetPayPension,
							smp_ytd: data.statutoryMaternityPay,
							spp_ytd: data.statutoryPaternityPay,
							sap_ytd: data.statutoryAdoptionPay,
							ssp_ytd: data.statutorySickPay,
							spbp_ytd: data.parentalBereavement,
							shpp_ytd: data.sharedParentalPay,
						},
						ni_categories: categories.map((c) => ({
							national_insurance_category: c.nationalInsuranceCategory,
							earnings_at_lel_ytd: c.earningsAtLeL,
							earnings_to_pt_ytd: c.earningsToPT,
							earnings_to_uel_ytd: c.earningsToUEL,
							employee_nic_ytd: c.employeeNationalInsurance,
							employer_nic_ytd: c.employerNationalInsurance,
							gross_pay_for_nic_ytd: c.grossPayForNationalInsurance,
							director_at_lel_ytd: c.directorEarningsAtLeL,
							director_to_pt_ytd: c.directorEarningsToPT,
							director_to_uel_ytd: c.directorEarningsToUEL,
							director_nic_ytd: c.directorNationalInsurance,
							director_employer_nic_ytd: c.directorEmployerNationalInsurance,
							director_earnings_ytd: c.directorGrossPayForNationalInsurance,
						})),
					}
				}
			});
		},
		[categories, data, employeeId, updateEmployee]
	);

	useEffect(() => {
		const dynamicCategories = employee?.active_ni_categories_ytd;
		if (!employee || !dynamicCategories || !dynamicCategories.length)
			return;

		const newCategories = dynamicCategories.map((c) => ({
			nationalInsuranceCategory: c.national_insurance_category,
			earningsAtLeL: c.earnings_at_lel_ytd,
			earningsToPT: c.earnings_to_pt_ytd,
			earningsToUEL: c.earnings_to_uel_ytd,
			employeeNationalInsurance: c.employee_nic_ytd,
			employerNationalInsurance: c.employer_nic_ytd,
			grossPayForNationalInsurance: c.gross_pay_for_nic_ytd,

			directorEarningsAtLeL: c.director_at_lel_ytd,
			directorEarningsToPT: c.director_to_pt_ytd,
			directorEarningsToUEL: c.director_to_uel_ytd,
			directorNationalInsurance: c.director_nic_ytd,
			directorEmployerNationalInsurance: c.director_employer_nic_ytd,
			directorGrossPayForNationalInsurance: c.director_earnings_ytd,
		}));

		setCategories(newCategories);
	}, [employee]);

	useEffect(() => {
		if (!employee || !employee.ytd_figure) return;

		setData({
			previousPayToDate: employee.ytd_figure.previous_employment_taxable_pay,
			previousTaxToDate: employee.ytd_figure.previous_employment_tax_deducted,
			grossForTax: employee.ytd_figure.gross_for_tax_ytd,
			taxDeducted: employee.ytd_figure.tax_deducted_ytd,
			studentLoan: employee.ytd_figure.student_loan_ytd,
			postgraduateLoan: employee.ytd_figure.pg_loan_ytd,
			employeePension: employee.ytd_figure.employee_pension_ytd,
			employerPension: employee.ytd_figure.employer_pension_ytd,

			benefitInKindPayrolledAmount:
				employee.ytd_figure.bik_payrolled_amount_ytd,
			employeeNetPayPension:
				employee.ytd_figure.employee_net_pay_pension_ytd,

			statutoryMaternityPay: employee.ytd_figure.smp_ytd,
			statutoryPaternityPay: employee.ytd_figure.spp_ytd,
			statutoryAdoptionPay: employee.ytd_figure.sap_ytd,
			statutorySickPay: employee.ytd_figure.ssp_ytd,
			parentalBereavement: employee.ytd_figure.spbp_ytd,
			sharedParentalPay: employee.ytd_figure.shpp_ytd,
		});
	}, [employee]);
	
	return (
		<form onSubmit={(e) => onSubmit(e)}>
			<div style={{ display: 'flex', flexDirection: 'column', gap: '1.83vw' }} >
				<IncomeTax data={data} errors={errors} handleDataChange={handleDataChange} isEditable={isEditable} />
				<StatutoriesAndStudentLoans data={data} errors={errors} handleDataChange={handleDataChange} isEditable={isEditable} />
				<PayrolledBenefitsAndPensions data={data} errors={errors} handleDataChange={handleDataChange} isEditable={isEditable} />
				<NationalInsurance employee={employee} categories={categories} categoriesError={categoriesError} handleCategoryChange={handleCategoryChange} isEditable={isEditable} />
				<div style={{ display: 'flex', gap: '0.8vw' }} >
					<FreepayrollButton
						variant="white-button"
						custom={true}
						fullWidth={false}
						width={5}
						height={1}
						isLoading={isPending}
					>
						Cancel
					</FreepayrollButton>
					<FreepayrollButton
						type="submit"
						variant="primary"
						custom={true}
						fullWidth={false}
						width={5}
						height={1}
						isLoading={isPending}
					>
						Save
					</FreepayrollButton>
				</div>
			</div>
		</form>
	);
}
