import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import {
	Chip,
	Divider,
	FreepayrollButton,
	Dropbox,
	FreepayrollSelect,
	Icon,
	Modal,
	Typography, FreepayrollDateField,
} from '@collegia-partners/ui-kit';
import { useGetEmployer } from '../../../../hooks/employer';
import {
	useDownloadEPS,
	useGetEpsReport,
	useSubmitEPS,
} from '../../../../hooks/reports/eps';
import FPTable from '../../../../components/common/FPTable/FPTable';
import { keyframes } from '@emotion/react';
import Box from '@mui/material/Box';
import moment from "moment/moment";

function getStatusColor(status) {
	return {
		Pending: '#FD8A03',
		Error: '#FD0303',
		Accepted: '#0360FD',
	}[status];
}

function getStatus(employer_eps_submission) {
	return !employer_eps_submission.is_error &&
		!employer_eps_submission.is_success
		? 'Pending'
		: employer_eps_submission.is_error
		? 'Error'
		: 'Accepted';
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ReportsEps = (): JSX.Element => {
	const { employer } = useGetEmployer({
		relations: ['employer_tax_years.tax_year', 'tax_year'],
	});
	const { mutate: submitEPS } = useSubmitEPS();
	const { mutate: downloadEps } = useDownloadEPS();

	const [openEpsModal, setOpenEpsModal] = useState(false),
		[epsMode, setEpsMode] = useState(''),
		[taxMonth, setTaxMonth] = useState(0),
		[noPaymentPeriod, setNoPaymentPeriod] = useState(0),
		[schemeCloseDate, setSchemeCloseDate] = useState(''),
		[selectedTaxYearId, setSelectedTaxYearId] = useState(0),
		[selectedEps, setSelectedEps] = useState({}),
		[taxYears, setTaxYears] = useState([]),
		[taxYearsOptions, setTaxYearsOptions] = useState([]);

	const blink = keyframes`
		  0%, 100% {
		    opacity: 1;
		  }
		  50% {
		    opacity: 0;
		  }
		`;

	const columns = [
		{
			text: 'Submission Date',
			key: 'created_at',
			customRender: (row, index) => (
				<Typography
					variant={'body'}
					size={'medium'}
					key={index}
					color={'black'}
				>
					{format(parseISO(row?.created_at), 'dd MMM yy')}
				</Typography>
			),
		},
		{
			text: 'Status',
			key: 'status',
			customRender: (row) => (
				<Box
					sx={{
						animation:
							getStatus(row) === 'Pending' &&
							`${blink} 1.6s ease-in-out infinite`,
					}}
				>
					<Chip color={getStatusColor(getStatus(row))}>{getStatus(row)}</Chip>
				</Box>
			),
		},
		{
			text: 'Submission Type',
			key: 'submission_type',
			customRender: (row, index) => (
				<Typography variant={'body'} color={'black'} key={index}>
					{row?.tax_month_submission
						? 'Employer Payment Summary'
						: row?.scheme_ceased_submission
						? 'Close Paye Scheme'
						: row?.bank_details_submission
						? 'Bank Details Submission'
						: row?.employment_allowance_submission
						? 'Employment Allowance Claim'
						: 'No Payment Submission'}
				</Typography>
			),
		},
		{
			text: 'Tax month',
			key: 'tax_month',
			customRender: (row, index) => (
				<Typography
					variant={'body'}
					size={'medium'}
					key={index}
					color={'black'}
				>
					{row?.tax_month_submission && row?.employer_payment_summary?.tax_month
						? `${row?.employer_payment_summary?.tax_month} (${format(
								parseISO(row?.employer_payment_summary?.start_date),
								'dd MMM yy',
						  )} - ${format(
								parseISO(row?.employer_payment_summary?.end_date),
								'dd MMM yy',
						  )})`
						: 'N/A'}
				</Typography>
			),
		},
		{
			text: 'Actions',
			key: '',
			customRender: (row) => (
				<Dropbox
					target={
						<button style={{ all: 'unset', cursor: 'pointer' }}>
							<Icon
								iconName="dots-horizontal"
								className={'EmployeeSummaryIcon'}
							/>
						</button>
					}
				>
					<div className={'EmployeeSummaryActions'}>
						{row?.is_error && row?.tax_month_submission && (
							<button
								className={'EmployeeSummaryAction'}
								onClick={() => {
									setSelectedEps(row);
									setEpsMode('regular');
									setOpenEpsModal(true);
								}}
							>
								Resubmit EPS
							</button>
						)}

						<button
							className={'EmployeeSummaryAction'}
							onClick={() => {
								downloadEps({
									eps_submissions: {
										eps_report_id: row?.id,
									},
								});
							}}
						>
							Download Submission
						</button>
					</div>
				</Dropbox>
			),
		},
	];

	useEffect(() => {
		if (employer?.employer_tax_years?.length > 0) {
			setTaxYears(
				employer?.employer_tax_years?.find(
					(taxYear) => taxYear?.tax_year?.id === employer?.tax_year_id,
				)?.tax_year,
			);
			setSelectedTaxYearId(taxYears?.id);
		}
	}, [employer, taxYears?.id]);

	useEffect(() => {
		if (employer?.employer_tax_years?.length > 0) {
			setTaxYearsOptions(
				employer?.employer_tax_years.map((taxYear) => taxYear?.tax_year),
			);
		}
	}, [employer]);

	const taxYearStart = '20' + taxYears?.formated_tax_year?.split('-')[0];
	const taxYearEnd = '20' + taxYears?.formated_tax_year?.split('-')[1];

	const taxMonths = [
		{
			label: `Month 1: 6th April ${taxYearStart} - 5th May ${taxYearStart}`,
			value: 1,
		},
		{
			label: `Month 2: 6th May ${taxYearStart} - 5th June ${taxYearStart}`,
			value: 2,
		},
		{
			label: `Month 3: 6th June ${taxYearStart} - 5th July ${taxYearStart}`,
			value: 3,
		},
		{
			label: `Month 4: 6th July ${taxYearStart} - 5th August ${taxYearStart}`,
			value: 4,
		},
		{
			label: `Month 5: 6th August ${taxYearStart} - 5th September ${taxYearStart}`,
			value: 5,
		},
		{
			label: `Month 6: 6th September ${taxYearStart} - 5th October ${taxYearStart}`,
			value: 6,
		},
		{
			label: `Month 7: 6th October ${taxYearStart} - 5th November ${taxYearStart}`,
			value: 7,
		},
		{
			label: `Month 8: 6th November ${taxYearStart} - 5th December ${taxYearStart}`,
			value: 8,
		},
		{
			label: `Month 9: 6th December ${taxYearStart} - 5th January ${taxYearEnd}`,
			value: 9,
		},
		{
			label: `Month 10: 6th January ${taxYearEnd} - 5th February ${taxYearEnd}`,
			value: 10,
		},
		{
			label: `Month 11: 6th February ${taxYearEnd} - 5th March ${taxYearEnd}`,
			value: 11,
		},
		{
			label: `Month 12: 6th March ${taxYearEnd} - 5th April ${taxYearEnd}`,
			value: 12,
		},
	];

	return (
		<div id={'reports-eps'}>
			<div className={'reports-eps-item'}>
				<Typography variant={'title'} weight={'bold'} color={'#0160FD'}>
					Employer Payment Summary (EPS)
				</Typography>
				<Typography variant={'subtitle2'} weight={'normal'} color={'gray'}>
					Monthly reports with the employer payment summary which contain values
					that <br />
					can’t be included in the full payment submission (FPS).
				</Typography>
				<div className={'reports-eps-divider'}>
					<Divider color={'light-gray'} />
				</div>

				<div className={'eps-drop-boxes'}>
					<Dropbox
						target={
							<button style={{ all: 'unset', cursor: 'pointer' }}>
								<FreepayrollButton size={'small'} variant={'primary'}>
									New EPS Submission
								</FreepayrollButton>
							</button>
						}
					>
						<div className={'EmployeeSummaryActions'}>
							<button
								className={'EmployeeSummaryAction'}
								onClick={() => {
									setOpenEpsModal(true);
									setEpsMode('no_payment');
								}}
							>
								+ No payment submission
							</button>
							<button
								className={'EmployeeSummaryAction'}
								onClick={() => {
									setOpenEpsModal(true);
									setEpsMode('tax_month_submission');
								}}
							>
								+ Tax month submission
							</button>

							{/*<button*/}
							{/*	className={"EmployeeSummaryAction"}*/}
							{/*	onClick={() => {*/}
							{/*		setOpenEpsModal(true);*/}
							{/*		setEpsMode("employment_allowance_submission");*/}
							{/*	}}*/}
							{/*>*/}
							{/*	+ Employment allowance claim*/}
							{/*</button>*/}

							<button className={'EmployeeSummaryAction'} disabled={true}>
								+ Bank details submission
							</button>
							<button
								className={'EmployeeSummaryAction'}
								onClick={() => {
									setOpenEpsModal(true);
									setEpsMode('close_paye_scheme');
								}}
							>
								+ Close PAYE scheme
							</button>
						</div>
					</Dropbox>
					<div>
						<FreepayrollSelect
							variant={'calendar'}
							options={taxYearsOptions.map((e) => ({
								value: e.id,
								label: e.formated_tax_year,
							}))}
							onSelect={(e) => {
								if (!e || !e.value) return;
								setSelectedTaxYearId(e.value);
							}}
							value={selectedTaxYearId || 0}
							defaultValue={selectedTaxYearId || 0}
							noGhostHelperText
							noGhostLabel
						/>
					</div>
				</div>
				<FPTable
					headers={columns}
					hookName={useGetEpsReport}
					hookKey={'report_eps'}
					hookParams={{ taxYearId: selectedTaxYearId }}
				/>
			</div>
			<Modal
				onClose={() => {
					setOpenEpsModal(false);
					setNoPaymentPeriod(0);
					setEpsMode('');
				}}
				isOpen={openEpsModal}
				maxWidth={'sm'}
			>
				<div style={{ display:'flex', flexDirection: 'column', gap: '20px' }} >
					<Typography variant={'title'} color={'#0160FD'} weight={'bold'}>
						CONFIRM EPS SUBMISSION
					</Typography>
					{(epsMode === 'no_payment' || epsMode === 'tax_month_submission') && (
						<FreepayrollSelect
							label={''}
							value={noPaymentPeriod}
							onSelect={(option) => {
								if (epsMode === 'no_payment') {
									setNoPaymentPeriod(option?.value)
									return;
								}

								if (epsMode === 'tax_month_submission') {
									setTaxMonth(option?.value)
								}
							}}
							options={taxMonths}
						/>
					)}
					{epsMode === 'close_paye_scheme' && (
						<FreepayrollDateField
							label={'Scheme Close Date'}
							value={moment(schemeCloseDate)}
							onChange={(newValue) => {
								setSchemeCloseDate(newValue.format("YYYY-MM-DD"));
							}}
							minDate={moment(employer?.tax_year?.from ?? null)}
							maxDate={moment()}
						/>
					)}
					<Typography weight={'normal'} variant={'subtitle2'} color={'black'}>
						Do you confirm that you want to submit EPS ?
						<ul>
							<li>Tax Year: {employer?.tax_year?.formated_tax_year}</li>
							{epsMode === 'close_paye_scheme' && (
								<>
									<li style={{ paddingTop: '10px' }} >
										Submitting this to HMRC, will close your PAYE scheme and no further submissions,
										will be allowed by HMRC.
									</li>
								</>
							)}
							{epsMode === 'tax_month_submission' && (
								<>
									<li>Tax Month Submission: {taxMonth}</li>
								</>
							)}
							{epsMode === 'regular' && (
								<>
									<li>Tax Month Submission: {selectedEps?.tax_month}</li>
								</>
							)}
							{epsMode === 'employment_allowance_submission' && (
								<>
									<li>Employment Allowance claim:</li>
								</>
							)}
							{epsMode === 'no_payment' && (
								<>
									<li>No Payment submission</li>
									<li>
										Period:{' '}
										{
											taxMonths?.find(
												(taxMonth) => taxMonth.value === noPaymentPeriod,
											)?.label
										}
									</li>
								</>
							)}
						</ul>
						Once you click on confirm, we will send automatically the required
						data to HMRC for RTI purposes.
					</Typography>
				</div>
				<div className={'reports-eps-modal-button'}>
					<FreepayrollButton
						fullWidth
						onClick={() => {
							setOpenEpsModal(false);
							setEpsMode('');
							setNoPaymentPeriod(0);
							setSelectedEps({});
							setTaxMonth(0);
						}}
						variant={'white-button'}
						size={'medium'}
						custom
						height={0.85}
						width={5.5}
					>
						CANCEL
					</FreepayrollButton>
					<FreepayrollButton
						fullWidth
						onClick={() => {
							submitEPS({
								pay_schedule_runs: {
									eps_mode: epsMode,
									no_payment_period: noPaymentPeriod,
									resubmit_eps_id: selectedEps?.id,
									scheme_ceased_date: schemeCloseDate,
									tax_month: taxMonth,
								},
							});
							setEpsMode('');
							setNoPaymentPeriod(0);
							setOpenEpsModal(false);
							setSelectedEps({});
							setTaxMonth(0);
						}}
						variant={'primary'}
						size={'medium'}
						custom
						height={0.85}
						width={5.5}
						disabled={
							(epsMode === 'no_payment' && noPaymentPeriod === 0) ||
							(epsMode === 'close_paye_scheme' && !schemeCloseDate) ||
							(epsMode === 'tax_month_submission' && taxMonth === 0)
						}
					>
						CONFIRM
					</FreepayrollButton>
				</div>
			</Modal>
		</div>
	);
};

export default ReportsEps;
