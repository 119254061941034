import { Button, Typography, Select, MenuItem } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import React, {useMemo, useState} from 'react';
import {useGetTaxYears} from "../../../../../hooks/useGetTaxYears";
import Skeleton from "../../../../Skeleton";
import {FreepayrollSelect, FreepayrollTextField} from "@collegia-partners/ui-kit";
import {parseISO} from "date-fns";
import format from "date-fns/format";

const InnerStepOne = ({ stepZero, setStepZero, basicSetup = () => {} }) => {

	const { years, isPending } = useGetTaxYears();

	const startYear = useMemo(() => {
		return years?.find(year => String(year.id) === stepZero.starting_tax_year);
	}, [years, stepZero.starting_tax_year]);

	return (
		<div id={'inner-step-one'} style={{ display: 'flex', flexWrap: 'wrap' }}>
			<div className={'body-title-container'} style={{ width: '100%' }}>
				<Typography className={'body-title'}>
					Customising your setup experience
				</Typography>
			</div>

			{
				isPending ? (
					<div style={{ display: 'flex', width: '100%', marginTop: '20px' }} >
						<Skeleton width={'100%'} height={200} background={'#D6D8E1'}/>
					</div>
				) : (
					<div>
						<div className={'body-sub-container'} style={{ width: '100%' }}>
							<Typography className={'body-subtitle-1'}>
								Select the tax year in which your first payroll date with FreePayroll will take place
							</Typography>

							<div style={{ width: '40%' }}>

								<FreepayrollSelect
									options={years?.map((year) => ({
										value: String(year.id),
										label: format(parseISO(year.from),'dd MMM yy') + ' - ' + format(parseISO(year.to),'dd MMM yy'),
									}))}
									onSelect={(option) => {
										setStepZero({
											...stepZero,
											starting_tax_year: option.value,
										});
									}}
									size="medium"
								/>
							</div>
						</div>

						{
							stepZero?.starting_tax_year && (
								<div
									className={'body-sub-container-2'}
									style={{ flexBasis: '100%', maxWidth: '100%' }}
								>
									<Typography className={'body-subtitle-1'}>
										Will this be your company’s first pay day since 6 April {startYear?.from?.slice(0,4)}?
									</Typography>
									<RadioGroup
										row
										onChange={(event) => {
											setStepZero({
												...stepZero,
												first_tax_year_pay: event.target.value === 'true',
												first_payroll_ever: null,
												current_payroll_provider: ' ',
											});
										}}
									>
										<FormControlLabel value={true} control={<Radio />} label="Yes" />
										<FormControlLabel value={false} control={<Radio />} label="No" />
									</RadioGroup>
								</div>
							)
						}

						{stepZero.first_tax_year_pay === false && (
							<YearToDatesFields
								startYear={startYear}
								setStepZero={setStepZero}
								stepZero={stepZero}
							/>
						)}

						{stepZero.first_tax_year_pay === true && (
							<div
								className={'body-sub-container-3'}
								style={{ flexBasis: '100%', maxWidth: '100%' }}
							>
								<Typography className={'body-subtitle-1'}>
									Will this be the first payroll ever for this company?
								</Typography>
								<RadioGroup
									row
									onChange={(event) => {
										setStepZero({
											...stepZero,
											first_payroll_ever: event.target.value === 'true',
											current_payroll_provider: ' ',
										});
									}}
								>
									<FormControlLabel value={true} control={<Radio />} label="Yes" />
									<FormControlLabel value={false} control={<Radio />} label="No" />
								</RadioGroup>
							</div>
						)}

						{(stepZero.first_tax_year_pay === false ||
							(stepZero.first_tax_year_pay === true &&
								stepZero.first_payroll_ever === false)) && (
							<div className={'body-sub-container-4'} style={{ width: '40%' }}>
								<Typography className={'body-subtitle-1'}>
									Who is your current payroll provider?
								</Typography>
								<FormControl fullWidth>
									<Select
										className={'default-select'}
										id={'select-current-provider'}
										value={stepZero.current_payroll_provider}
										onChange={(event) => {
											setStepZero({
												...stepZero,
												current_payroll_provider: event.target.value,
											});
										}}
									>
										<MenuItem value={' '}>Select current provider...</MenuItem>
										<MenuItem value={'BrightPay'}>BrightPay</MenuItem>
										<MenuItem value={'Sage'}>Sage</MenuItem>
										<MenuItem value={'Iris'}>Iris</MenuItem>
										<MenuItem value={'MoneySoft'}>MoneySoft</MenuItem>
										<MenuItem value={'Basic PAYE Tools'}>Basic PAYE Tools</MenuItem>
										<MenuItem value={'Other'}>Other</MenuItem>
									</Select>
								</FormControl>
							</div>
						)}

						<div
							className={'button-container'}
							style={{
								display: 'flex',
								flexWrap: 'wrap',
								flexBasis: '100%',
								maxWidth: '100%',
							}}
						>
							<Button
								className={'default-black-button'}
								onClick={() =>
									basicSetup({
										employers: {
											step: 'payroll_dates',
											data_insert: {
												starting_tax_year_id: `${stepZero.starting_tax_year}`,
												first_pay_in_tax_year: stepZero.first_tax_year_pay,
												first_payroll_ever:
													stepZero.first_payroll_ever === null
														? false
														: stepZero.first_payroll_ever,
												current_payroll_provider: stepZero.current_payroll_provider,
												company_planning_pay: stepZero.myself
													? 'myself '
													: '' + stepZero.employees
														? 'employees '
														: '' + stepZero.not_paying
															? 'not_paying'
															: '',
											},
											smp_recovered_ytd: stepZero.smp_recovered_ytd,
											smp_nic_ytd: stepZero.smp_nic_ytd,
											spp_recovered_ytd: stepZero.spp_recovered_ytd,
											spp_nic_ytd: stepZero.spp_nic_ytd,
											sap_recovered_ytd: stepZero.sap_recovered_ytd,
											sap_nic_ytd: stepZero.sap_nic_ytd,
											shpp_recovered_ytd: stepZero.shpp_recovered_ytd,
											shpp_nic_ytd: stepZero.shpp_nic_ytd,
											spbp_recovered_ytd: stepZero.spbp_recovered_ytd,
											spbp_nic_ytd: stepZero.spbp_nic_ytd,
										},
									})
								}
								disabled={
									!stepZero.starting_tax_year ||
									stepZero.first_tax_year_pay === null ||
									(stepZero.first_tax_year_pay === false &&
										stepZero.current_payroll_provider === ' ') ||
									(stepZero.first_tax_year_pay === true &&
										stepZero.first_payroll_ever === false &&
										stepZero.current_payroll_provider === ' ') ||
									(stepZero.first_tax_year_pay === true &&
										stepZero.first_payroll_ever === null)
								}
							>
								Submit
							</Button>
						</div>
					</div>
				)
			}
		</div>
	);
};

export default InnerStepOne;

function YearToDatesFields({
	stepZero,
	setStepZero,
	startYear,
}) {

	const [show, setShow] = useState(false);
	const employerStatutory = [
		{
			label: 'Recovered SMP YTD',
			value: stepZero?.smp_recovered_ytd,
			key: 'smp_recovered_ytd',
		},
		{
			label: 'SMP NI Compensation YTD',
			value: stepZero?.smp_nic_ytd,
			key: 'smp_nic_ytd',
		},
		{
			label: 'Recovered SPP YTD',
			value: stepZero?.spp_recovered_ytd,
			key: 'spp_recovered_ytd',
		},
		{
			label: 'SPP NI Compensation YTD',
			value: stepZero?.spp_nic_ytd,
			key: 'spp_nic_ytd',
		},
		{
			label: 'Recovered SAP YTD',
			value: stepZero?.sap_recovered_ytd,
			key: 'sap_recovered_ytd',
		},
		{
			label: 'SAP NI Compensation YTD',
			value: stepZero?.sap_nic_ytd,
			key: 'sap_nic_ytd',
		},
		{
			label: 'Recovered SPBP YTD',
			value: stepZero?.spbp_recovered_ytd,
			key: 'spbp_recovered_ytd',
		},
		{
			label: 'SPBP NI Compensation YTD',
			value: stepZero?.spbp_nic_ytd,
			key: 'spbp_nic_ytd',

		},
		{
			label: 'Recovered ShPP YTD',
			value: stepZero?.shpp_recovered_ytd,
			key: 'shpp_recovered_ytd',
		},
		{
			label: 'ShPP NI Compensation YTD',
			value: stepZero?.shpp_nic_ytd,
			key: 'shpp_nic_ytd',
		},
	];

	return (
		<div
			className={'body-sub-container-2'}
			style={{ flexBasis: '100%', maxWidth: '100%' }}
		>
			<Typography className={'body-subtitle-1'}>
				Did you claim any statutory payments since 6 April {startYear?.from?.slice(0,4)}?
			</Typography>
			<RadioGroup
				row
				onChange={(event) => {
					setShow(event.target.value.toString() === 'true');
				}}
			>
				<FormControlLabel value={true} control={<Radio />} label="Yes" />
				<FormControlLabel value={false} control={<Radio />} label="No" />
			</RadioGroup>
			{
				show === true &&
				<Typography className={'body-subtitle-1'}>
					If you don't have the exact figures now, you will be able to enter them later.
				</Typography>
			}
			{
				show === true && (
					<div
						style={{
							display: 'grid',
							gridTemplateColumns: '1fr 1fr',
							gap: '3vw',
							marginTop: '20px',
						}}
					>
						{
							employerStatutory.map((statutory, index) => (
								<FreepayrollTextField
									fieldType={"currency"}
									name={statutory.label}
									key={index}
									label={statutory.label}
									value={statutory.value}
									onChange={(event) => {
										setStepZero({
											...stepZero,
											[statutory.key]: event.target.value,
										});
									}}
									maxDecimalPlaces={2}
								/>
							))
						}
					</div>
				)
			}
		</div>
	);
}
