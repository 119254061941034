import React, {useEffect, useState} from "react";
import {
	FreepayrollButton,
	FreepayrollTextField,
} from "@collegia-partners/ui-kit";
import {
	useUpdateEmployee
} from "../../../../../hooks/employees";
import {useGetEmployee} from "../../../../../hooks/employee/useGetEmployee";
import {useParams} from "react-router-dom";

function EmployeeRecordItem({
    label,
    value,
    onChange,
    error = false,
}) {
	return (
		<div style={{ width: "fit-content", minWidth: '40%'  }}>
			<FreepayrollTextField
				fieldType={"text"}
				label={label}
				name={label}
				value={value}
				onChange={onChange}
				error={error}
			/>
		</div>
	);
}

export const BankDetailsTab = () => {

	const employeeId = useParams().employeeId;

	const { employee } = useGetEmployee({ id: employeeId, relations: ['bank_detail'] });
	const { mutateAsync: updateEmployee, isPending } = useUpdateEmployee();

	const [bankDetails, setBankDetails] = useState(employee?.bank_detail);

	const EmployeeDetailsLeft = [
		{
			label: "Bank Name",
			value: bankDetails?.bank_name,
			onChange: (e) => {
				setBankDetails({ ...bankDetails, bank_name: e.target.value });
			},
		},
		{
			label: "Sort Code",
			value: bankDetails?.sort_code,
			onChange: (e) => {
				setBankDetails({ ...bankDetails, sort_code: e.target.value });
			},
		},
		{
			label: "Account Number",
			value: bankDetails?.account_number,
			onChange: (e) => {
				setBankDetails({ ...bankDetails, account_number: e.target.value });
			},
		},
	];

	useEffect(() => {
		setBankDetails({
			'bank_name': employee?.bank_detail?.bank_name ?? "",
			'sort_code': employee?.bank_detail?.sort_code ?? "",
			'account_number': employee?.bank_detail?.account_number ?? "",
		})
	}, [employee]);

	return (
		<div style={{display: "flex", flexDirection: "column"}}>
			{EmployeeDetailsLeft.map((item, index) => (
				<EmployeeRecordItem key={index} {...item} />
			))}
			<div style={{ display:'flex', gap: "2vw", marginTop: "40px" }} >
				<FreepayrollButton
					variant={"white-button"}
					isLoading={isPending}
					custom={true}
					fullWidth={false}
					width={5}
					height={0.8}
					onClick={() => {
						setBankDetails({
							'bank_name': employee?.bank_detail?.bank_name,
							'sort_code': employee?.bank_detail?.sort_code,
							'account_number': employee?.bank_detail?.account_number,
						});
					}}
				>
					Cancel
				</FreepayrollButton>
				<FreepayrollButton
					variant={"primary"}
					isLoading={isPending}
					custom={true}
					fullWidth={false}
					width={5}
					height={0.8}
					disabled={
						bankDetails?.bank_name?.length === 0 ||
						bankDetails?.sort_code?.length === 0 ||
						bankDetails?.account_number?.length === 0
					}
					onClick={async () => {
						try {
							await updateEmployee({
								safeId: employeeId,
								data: {
									employees: {
										'action': 'bank_details',
										'bank_name': bankDetails?.bank_name,
										'sort_code': bankDetails?.sort_code,
										'account_number': bankDetails?.account_number,
									}
								}
							});
						} catch (error) {
							console.error(error);
						}
					}}
				>
					Save
				</FreepayrollButton>
			</div>
		</div>
	);
};
