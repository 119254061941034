import React, { useEffect, useReducer } from 'react';
import { useLoggedLayout } from '../../../context/LoggedLayoutContext';
import {
	FreepayrollButton,
	Dropbox,
	FreepayrollSelect,
	Typography,
} from '@collegia-partners/ui-kit';
import FPTable from '../../../components/common/FPTable/FPTable';
import {
	useDownloadTaxesFillings,
	useGetTaxesFillingsReportTable,
} from '../../../hooks/reports/taxes-fillings';
import { useGetEmployer } from '../../../hooks/employer';

function reducer(state, action) {
	switch (action.type) {
		case 'setTaxYear':
			return { ...state, taxYear: action.payload };
		case 'setFromTaxMonth':
			return { ...state, fromTaxMonth: action.payload };
		case 'setToTaxMonth':
			return { ...state, toTaxMonth: action.payload };
		default:
			throw new Error();
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ReportsTaxesFilings = (): JSX.Element => {
	const { setLayoutProps } = useLoggedLayout();

	const {
		mutate: downloadTaxesFillings,
		isPending: isDownloadingTaxesFillings,
	} = useDownloadTaxesFillings();

	const initialState = {
		taxYear: '',
		fromTaxMonth: 1,
		toTaxMonth: 12,
	};

	const [state, dispatch] = useReducer(reducer, initialState);

	const { employer } = useGetEmployer({
		relations: ['employer_tax_years.tax_year'],
	});

	const taxMonths = [
		{
			label: 'Tax month 1',
			value: 1,
		},
		{
			label: 'Tax month 2',
			value: 2,
		},
		{
			label: 'Tax month 3',
			value: 3,
		},
		{
			label: 'Tax month 4',
			value: 4,
		},
		{
			label: 'Tax month 5',
			value: 5,
		},
		{
			label: 'Tax month 6',
			value: 6,
		},
		{
			label: 'Tax month 7',
			value: 7,
		},
		{
			label: 'Tax month 8',
			value: 8,
		},
		{
			label: 'Tax month 9',
			value: 9,
		},
		{
			label: 'Tax month 10',
			value: 10,
		},
		{
			label: 'Tax month 11',
			value: 11,
		},
		{
			label: 'Tax month 12',
			value: 12,
		},
	];

	const columns = [
		{
			key: 'tax_month',
			text: 'Tax Month',
			customRender: (row) => (
				<Typography color={'black'} weight={'bold'} variant={'body'}>
					{row?.tax_month}
				</Typography>
			),
		},
		{
			key: 'total_paye',
			text: 'Total PAYE',
			customRender: (row) => (
				<Typography color={'black'} weight={'bold'} variant={'body'}>
					{row?.total_paye}
				</Typography>
			),
		},
		{
			key: 'gross_ni_payable',
			text: 'Gross National Insurance',
			customRender: (row) => (
				<Typography color={'black'} weight={'bold'} variant={'body'}>
					{row?.gross_ni_payable}
				</Typography>
			),
		},
		{
			key: 'claimed_employment_allowance',
			text: 'Claimed Employment Allowance',
			customRender: (row) => (
				<Typography color={'black'} weight={'bold'} variant={'body'}>
					{row?.claimed_employment_allowance}
				</Typography>
			),
		},
		{
			key: 'total_statutory_recoveries',
			text: 'Total Statutory Recoveries',
			customRender: (row) => (
				<Typography color={'black'} weight={'bold'} variant={'body'}>
					{row?.total_statutory_recoveries}
				</Typography>
			),
		},
		{
			key: 'apprentice_levy',
			text: 'Apprentice Levy',
			customRender: (row) => (
				<Typography color={'black'} weight={'bold'} variant={'body'}>
					{row?.apprentice_levy}
				</Typography>
			),
		},
		{
			key: 'cis_deductions',
			text: 'CIS Deductions',
			customRender: (row) => (
				<Typography color={'black'} weight={'bold'} variant={'body'}>
					{row?.cis_deductions}
				</Typography>
			),
		},
		{
			key: 'total_amount_due',
			text: 'Amount Due',
			customRender: (row) => (
				<Typography color={'black'} weight={'bold'} variant={'body'}>
					{row?.total_amount_due}
				</Typography>
			),
		},
	];

	useEffect(() => {
		dispatch({ type: 'setTaxYear', payload: employer?.tax_year_id });
	}, [employer?.tax_year_id]);

	useEffect(() => {
		setLayoutProps({
			activePage: 'Reports',
			showBackButton: true,
			backLink: '/main/reports',
		});
	}, [setLayoutProps]);

	return (
		<div id={'reports-taxes-filling'}>
			<div className={'reports-taxes-fillings-payment-summary'}>
				<Typography variant={'title'} weight={'bold'} color={'primary'}>
					Payment Summary
				</Typography>
				<div>
					<Dropbox
						target={
							<button style={{ all: 'unset', cursor: 'pointer' }}>
								<FreepayrollButton
									variant={'primary'}
									custom={true}
									width={3}
									height={0.5}
								>
									Actions
								</FreepayrollButton>
							</button>
						}
						className={'EmployeeSummaryActions'}
					>
						<button
							className={'EmployeeSummaryAction'}
							onClick={() =>
								downloadTaxesFillings({
									from_tax_month: state.fromTaxMonth,
									to_tax_month: state.toTaxMonth,
									tax_year_id: state.taxYear,
									downloadFormat: 'csv',
								})
							}
							disabled={isDownloadingTaxesFillings}
						>
							{isDownloadingTaxesFillings
								? 'Downloading...'
								: 'Download csv report'}
						</button>
						<button className={'EmployeeSummaryAction'} disabled>
							Download pdf report
						</button>
					</Dropbox>
				</div>
			</div>

			<div className={'reports-taxes-fillings-selects'}>
				{/*Tax Year*/}
				<FreepayrollSelect
					label={'Tax Year'}
					value={state.taxYear}
					onSelect={(option) =>
						dispatch({ type: 'setTaxYear', payload: option.value })
					}
					options={
						employer?.employer_tax_years?.map((tax_year) => ({
							value: tax_year.tax_year_id,
							label: tax_year.tax_year.formated_tax_year,
						})) || []
					}
					size={'small'}
				/>

				{/*From tax month*/}
				<FreepayrollSelect
					label={'From tax month'}
					value={state.fromTaxMonth}
					onSelect={(option) => {
						dispatch({ type: 'setFromTaxMonth', payload: option.value });
					}}
					options={
						taxMonths?.map((taxMonth) => ({
							value: taxMonth.value,
							label: taxMonth.label,
						})) || []
					}
					size={'small'}
				/>

				{/*To tax month*/}
				<FreepayrollSelect
					label={'To tax month'}
					value={state.toTaxMonth}
					onSelect={(option) => {
						dispatch({ type: 'setToTaxMonth', payload: option.value });
					}}
					options={
						taxMonths?.map((taxMonth) => ({
							value: taxMonth.value,
							label: taxMonth.label,
						})) || []
					}
					size={'small'}
				/>
			</div>

			<FPTable
				headers={columns}
				hookName={useGetTaxesFillingsReportTable}
				hookKey={'report_taxes_fillings'}
				hookParams={{
					from_tax_month: state.fromTaxMonth,
					to_tax_month: state.toTaxMonth,
					tax_year_id: state.taxYear,
				}}
			/>
		</div>
	);
};

export default ReportsTaxesFilings;
