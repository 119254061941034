import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoggedLayout } from '../../../context/LoggedLayoutContext';
import FPTable from '../../../components/common/FPTable/FPTable';
import { useDeleteEmployee, useListEmployees } from '../../../hooks/employees';
import {
	Dropbox,
	Icon,
	Chip,
	Typography,
	FreepayrollButton,
	FreepayrollTextField,
} from '@collegia-partners/ui-kit';
import { renderPensionStatusColor } from '../../../utils/Helpers';
import { useListPaySchedules } from '../../../hooks/pay-schedules';
import { EmployeeOnHold } from '../../../components/common/EmployeeOnHold';

export function getEmployeeStatus(status) {
	switch (status) {
		case 'Active':
			return '#0360FD';
		case 'Pending Information':
			return '#000';
		default:
			return '#C4C4C4';
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const TeamMembers = (): JSX.Element => {
	const push = useNavigate();
	const { setLayoutProps } = useLoggedLayout();
	const [isOpenStatusModal, setIsOpenStatusModal] = useState(false);

	const [searchText, setSearchText] = useState('');
	const [debouncedSearchText, setDebouncedSearchText] = useState('');

	const { mutate: deleteEmployee, isPending: isDeleting } = useDeleteEmployee();

	const { paySchedules } = useListPaySchedules({ page: 1, perPage: 20 });

	const columns = [
		{
			key: 'name',
			text: 'Name',
			hasOrderBy: true,
		},
		{
			key: 'status',
			text: 'Payroll Status',
			customRender: (row, index) => (
				<Chip key={index} color={getEmployeeStatus(row.status)}>
					{row.status}
				</Chip>
			),
			filter: true,
			filterOptions: [
				{ value: 'Active' },
				{ value: 'Pending Information' },
				{ value: 'Terminated' },
				{ value: 'On Hold' },
			],
		},
		{
			key: 'pay_schedule.name',
			text: 'Pay Schedule',
			filter: true,
			filterOptions: paySchedules?.data?.map((schedule) => ({
				value: schedule.name,
			})),
		},
		{
			key: 'pension_assessment.ae_status',
			text: 'Pension Status',
			customRender: (row, index) => (
				<Chip key={index} color={renderPensionStatusColor(row?.pension_assessment?.ae_status)}>
					{row?.pension_assessment?.ae_status}
				</Chip>
			),
		},
		{
			key: 'safe_id',
			text: 'Actions',
			color: 'black',
			width: '0.1fr',
			customRender: (row, index) => (
				<Dropbox
					target={
						<button style={{ all: 'unset', cursor: 'pointer' }}>
							<Icon
								iconName="dots-horizontal"
								className={'EmployeeSummaryIcon'}
							/>
						</button>
					}
					key={index}
					className={'EmployeeSummaryActions'}
				>
					<button
						onClick={() => {
							push(`/main/people/record/${row.safe_id}`);
						}}
						className={'EmployeeSummaryAction'}
						disabled={row?.status === 'Pending Information' || isDeleting}
					>
						Edit employee
					</button>
					<button
						onClick={() => {
							deleteEmployee(row.safe_id);
						}}
						disabled={row?.fps_submitted || isDeleting}
						className={'EmployeeSummaryAction'}
					>
						{isDeleting ? 'Deleting...' : 'Delete employee'}
					</button>
					{row?.status === 'Pending Information' && (
						<button
							disabled={isDeleting}
							onClick={() => {
								push(`/main/people/finish-employee-setup/${row.safe_id}`);
							}}
							className={'EmployeeSummaryAction'}
						>
							Finish employee setup
						</button>
					)}
				</Dropbox>
			),
		},
	];

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedSearchText(searchText);
		}, 500);

		// Cleanup the timer if `searchText` changes before the delay completes
		return () => {
			clearTimeout(handler);
		};
	}, [searchText]);

	useEffect(() => {
		setLayoutProps({
			activePage: 'People',
			showBackButton: false,
		});
	}, [setLayoutProps]);

	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				gap: '2.57vw',
			}}
		>
			<EmployeeOnHold
				isOpen={isOpenStatusModal}
				setIsOpen={setIsOpenStatusModal}
			/>

			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Typography
					variant={'title'}
					size={'large'}
					color={'primary'}
					weight={'bold'}
				>
					Employees
				</Typography>
				<div style={{ width: 'fit-content', display: 'flex', gap: '0.520vw' }}>
					<FreepayrollButton
						style={{ height: '100px !important' }}
						size={'small'}
						variant={'outline'}
						height={1}
						custom
						onClick={() => {
							setIsOpenStatusModal(true);
						}}
					>
						On Hold
					</FreepayrollButton>

					<FreepayrollButton
						style={{ height: '100px !important' }}
						size={'small'}
						variant={'primary'}
						height={1}
						custom
						onClick={() => {
							push('/main/people/add-employee-method');
						}}
					>
						+ Add employees
					</FreepayrollButton>
				</div>
			</div>
			<div>
				<FreepayrollTextField
					fieldType={'text'}
					name={'search'}
					placeholder={'Search an employee'}
					noGhostLabel={true}
					value={searchText}
					onChange={(e) => {
						setSearchText(e.target.value);
					}}
				/>
			</div>
			<div>
				<FPTable
					headers={columns}
					hookName={useListEmployees}
					hookKey={'employees'}
					hookParams={{
						search: debouncedSearchText,
					}}
					paginate={true}
				/>
			</div>
		</div>
	);
};

export default TeamMembers;
