import React, {useEffect, useState} from "react";
import WarningIcon from "../../../../images/svg/ReportsIcon/p60-warning.svg";
import DownloadIcon from "../../../../images/svg/ReportsIcon/download-icon.svg";
import ErrorIcon from "../../../../images/svg/error.svg";
import DownloadReportDialog from "../DownloadReportDialog";
import {format, parseISO} from "date-fns";
import IconButton from "@mui/material/IconButton";
import {useLocation} from "react-router-dom";
import {useLoggedLayout} from "../../../../context/LoggedLayoutContext";
import {Typography, Divider, Accordion} from "@collegia-partners/ui-kit";
import {useDownloadFPS, useGetFpsReport} from "../../../../hooks/reports/fps";
import {FpsErrorsModal} from "../../../../components/Reports/FpsErrorsModal";
import {Skeleton} from "@mui/material";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ReportsFps = (): JSX.Element => {

	const {setLayoutProps} = useLoggedLayout();
	const { fps_report, isPending: isLoading } = useGetFpsReport();
	const { mutate: downloadFps } = useDownloadFPS();

	const [payScheduleRun, setPayScheduleRun] = useState([]),
		[title, setTitle] = useState(""),
		[open, setOpen] = useState(false),
		[payRunId, setPayRunId] = useState(0),
		[openFpsErrorsModal, setOpenFpsErrorsModal] = useState(false),
		[row, setRow] = useState({});

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const employerPayScheduleRunId = searchParams.get('employerPayScheduleId');
	const payScheduleId = searchParams.get('payScheduleId');
	const columns = [
		{
			text: "Submission Date",
			key: "created_at",
			customRender: (row, index) => {
				return (
					<Typography
						color={"black"}
						variant={"body"}
						fontWeight={"bold"}
						key={index}
					>
						{format(parseISO(row?.created_at), "dd/MM/yyyy hh:mm:ss")}
					</Typography>
				)
			}
		},
		{
			text: "Status",
			key: "status",
			customRender: (row) => {
				if (row?.is_error === false && row?.is_success === true) {
					return (
						<Typography
							color={"primary"}
							variant={"body"}
							weight={"bold"}
						>
							Success
						</Typography>
					);
				} else if (row?.is_error === true && row?.is_success === false) {
					return (
						<Typography
							variant={"body"}
							weight={"bold"}
							color={"red"}
						>
							Error
						</Typography>
					)
				} else {
					return (
						<Typography
							variant={"body"}
							weight={"bold"}
							color={"#707070"}
						>
							Processing
						</Typography>
					)
				}
			}
		},
		{
			text: "Actions",
			key: "id",
			customRender: (row: {
				is_error: boolean,
				id: number,
			}) => {
				return (
					<div
					>
						<IconButton
							onClick={() =>
								downloadFps({
									pay_schedule_runs: {
										fps_id: row.id,
									}
								})
							}
						>
							<img
								className={"fps-report-img-style"}
								src={DownloadIcon}
								alt={"Download"}
							/>
						</IconButton>
						
						{
							row.is_error &&
							<IconButton
								onClick={() => {
									setRow(row);
									setOpenFpsErrorsModal(true);
								}}
							>
								<img
									className={"fps-report-img-style"}
									src={ErrorIcon}
									alt={"View errors"}
								/>
							</IconButton>
						}
					</div>
				);
			},
		},
	];

	useEffect(() => {
		if (employerPayScheduleRunId !== null && payScheduleId !== null) {
			let employerPaySchedule = fps_report?.find(report => report?.id === parseInt(payScheduleId));
			let paySchedule = employerPaySchedule?.pay_runs?.find(payRun => payRun?.id === parseInt(employerPayScheduleRunId));
			setTitle(
				"Tax Period " + paySchedule?.tax_period +
				": " + paySchedule?.pay_run_period
			);
			setPayScheduleRun(paySchedule);
			setPayRunId(paySchedule?.id);
			setOpen(true);
		}
	}, [employerPayScheduleRunId, fps_report, payScheduleId]);

	useEffect(() => {
		setLayoutProps({
			activePage: 'Reports',
			showBackButton: true,
			backLink: '/main/reports',
		});
	}, [setLayoutProps]);

	return (
		<div id={"reports-fps"}>
			<div
				className={"reports-fps-item"}
			>
				<Typography
					variant={"title"}
					weight={"bold"}
					color={"#0160FD"}
				>
					Full Payment Submission (FPS)
				</Typography>
				<Typography
					variant={"subtitle2"}
					weight={"normal"}
					color={"gray"}
				>
					A Full Payment Submission (FPS) is a report sent to HM Revenue and Customs (HMRC)<br/>
					about payments and deductions made to employees every time they are paid.
				</Typography>
				<div
					className={"reports-fps-divider"}
				>
					<Divider color={"light-gray"} />
				</div>
				<div
					className={"reports-fps-warning-box"}
				>
					<img
						className={"reports-fps-warning-icon"}
						src={WarningIcon}
						alt={"WarningIcon"}
					/>
					<Typography
						color={"black"}
						weight={"bold"}
						variant={"body"}
					>
						Please find below all the FPS reports that have been sent to HMRC, you can download them for
						your future reference.
					</Typography>
				</div>
				{
					isLoading &&
						Array(5).fill(0).map((_, index) => (
							<Skeleton height={60} width="40%" />
						))
				}
				{
					fps_report.length === 0 && !isLoading &&
						<div className={"reports-fps-border-box"}>
							<Typography
								variant={"body"}
								color={"black"}
								weight={"bold"}
							>
								No FPS reports available to download
							</Typography>
							<Typography
								weight={"normal"}
								color={"#ABAEB7"}
								variant={"body"}
							>
								In order to have FPS reports, you need to have extraordinary items like statutory pay.
							</Typography>
						</div>
				}
				{
					fps_report.length > 0 &&
					<div className={"reports-fps-year-download"}>
						{
							fps_report?.map((item, itemIndex) => {
								return (
									<div key={itemIndex} className={"reports-fps-accordion-style"}>
										<Accordion
										 	title={item?.name}
										>
											{
												item?.pay_runs?.map((pay_run, pay_run_index) => (
													<div
														key={pay_run_index}
													>
														<div
															className={"reports-fps-accordion-content-style"}
															onClick={() => {
																setTitle(
																	"Tax Period " + pay_run?.tax_period +
																	": " + pay_run?.pay_run_period
																);
																setPayScheduleRun(pay_run);
																setPayRunId(pay_run?.id);
																setOpen(true);
															}}
														>
															<Typography
																variant={"body"}
																weight={"normal"}
																color={"#919196"}
															>
																Tax
																period: {pay_run?.tax_period} | {pay_run?.pay_run_period}
															</Typography>
														</div>
													</div>
												))
											}
										</Accordion>
										{
											fps_report.length - 1 !== itemIndex
												? <div
													className={"reports-fps-year-download-divider"}
												>
													<Divider light color={"light-gray"} />
												</div>
												: ""
										}
									</div>
								)
							})
						}
					</div>
				}
			</div>
			<DownloadReportDialog
				title={title}
				payScheduleRun={payScheduleRun}
				columns={columns}
				open={open}
				setOpen={setOpen}
				payRunId={payRunId}
				payScheduleId={payScheduleId}
			/>
			<FpsErrorsModal
				row={row}
				isOpen={openFpsErrorsModal}
				setIsOpen={setOpenFpsErrorsModal}
			/>
		</div>
	);
}

export default ReportsFps;