import React, {useEffect, useState} from "react";
import {
	FreepayrollButton,
	FreepayrollSelect,
	FreepayrollTextField,
	Modal,
	Typography
} from "@collegia-partners/ui-kit";
import {
	useUpdateEmployee
} from "../../../../../hooks/employees";
import {useGetEmployee} from "../../../../../hooks/employee/useGetEmployee";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {formatMoney} from "../../../../../utils/Helpers";
import {useParams} from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import styles from "../../../../../components/common/FPTable/styles.module.scss";
import {Decimal} from "decimal.js";
import {useInfinitePaySchedules} from "../../../../../hooks/pay-schedules";
import {Alert} from "@mui/material";

function SalaryModal({
	open,
	setOpen,
	employee,
}) {
	
	const { mutateAsync: updateEmployee, isPending } = useUpdateEmployee();
	
	const [employmentType, setEmploymentType] = useState('');
	const [salary, setSalary] = useState({
		annual_salary: '',
		monthly_salary: '',
		weekly_salary: '',
		hourly_salary: '',
		expected_work_hours_per_week: '',
	});

	const salaryOptions = [
		{ label: "Annual Salary", value: 'salaried' },
		{ label: "Hourly Salary", value: 'hourly' },
	];

	const SalaryFields = [
		{
			label: "Annual Salary",
			value: salary.annual_salary,
			name: "annual_salary",
			show: employmentType === 'salaried',
		},
		{
			label: "Monthly Salary",
			value: salary.monthly_salary,
			name: "monthly_salary",
			show: employmentType === 'salaried',
		},
		{
			label: "Weekly Salary",
			value: salary.weekly_salary,
			name: "weekly_salary",
			show: employmentType === 'salaried',
		},
		{
			label: "Hourly Salary",
			value: salary.hourly_salary,
			name: "hourly_salary",
			disabled: employmentType === 'salaried',
		},
		{
			label: "Expected hours of work per week",
			value: salary.expected_work_hours_per_week,
			name: "expected_work_hours_per_week",
			adornment: "Hours",
		}
	];

	const handleChange = (name, value) => {
		let updatedSalary = { ...salary, [name]: value };
		
		let parsedValue = '';
		
		if (value !== "") {
			parsedValue = new Decimal(value);
		}

		if (name === "annual_salary") {
			
			if (value === "") {
				setSalary({
					...updatedSalary,
					monthly_salary: '',
					weekly_salary: '',
					hourly_salary: '',
				})
				return;
			}
			
			const monthlySalary = parsedValue.dividedBy(12).toDecimalPlaces(2, Decimal.ROUND_HALF_UP).toNumber();
			const weeklySalary = parsedValue.dividedBy(52).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);

			updatedSalary = {
				...updatedSalary,
				monthly_salary: monthlySalary,
				weekly_salary: weeklySalary.toNumber(),
				hourly_salary: weeklySalary.dividedBy(updatedSalary.expected_work_hours_per_week || 0).toDecimalPlaces(2, Decimal.ROUND_HALF_UP).toNumber(),
			};
		}
		else if (name === "monthly_salary") {
			
			if (value === "") {
				setSalary({
					...updatedSalary,
					annual_salary: '',
					weekly_salary: '',
					hourly_salary: '',
				})
				return;
			}

			const annualSalary = parsedValue.times(12).toDecimalPlaces(2, Decimal.ROUND_HALF_UP).toNumber();
			const weeklySalary = parsedValue.times(12).dividedBy(52).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);

			updatedSalary = {
				...updatedSalary,
				annual_salary: annualSalary,
				weekly_salary: weeklySalary.toNumber(),
				hourly_salary: weeklySalary.dividedBy(updatedSalary.expected_work_hours_per_week || 0).toDecimalPlaces(2, Decimal.ROUND_HALF_UP).toNumber(),
			};

		}
		else if (name === "weekly_salary") {
			
			if (value === "") {
				setSalary({
					...updatedSalary,
					annual_salary: '',
					monthly_salary: '',
					hourly_salary: '',
				})
				return;
			}

			const annualSalary = parsedValue.times(52).toDecimalPlaces(2, Decimal.ROUND_HALF_UP).toNumber();
			const monthlySalary = parsedValue.times(52).dividedBy(12).toDecimalPlaces(2, Decimal.ROUND_HALF_UP);

			updatedSalary = {
				...updatedSalary,
				annual_salary: annualSalary,
				monthly_salary: monthlySalary.toNumber(),
				hourly_salary: parsedValue.dividedBy(updatedSalary.expected_work_hours_per_week || 0).toDecimalPlaces(2, Decimal.ROUND_HALF_UP).toNumber(),
			};
		}
		else if (name === "expected_work_hours_per_week") {
			if (employmentType === 'salaried') {
				updatedSalary = {
					...updatedSalary,
					hourly_salary: new Decimal(updatedSalary.weekly_salary).dividedBy(parsedValue).toDecimalPlaces(2, Decimal.ROUND_HALF_UP).toNumber(),
				};
			}
		}

		setSalary(updatedSalary);
	};
	
	useEffect(() => {
		setEmploymentType(employee?.salary?.employment_type);
		setSalary({
			annual_salary: employee?.salary?.annual_salary,
			monthly_salary: employee?.salary?.monthly_salary,
			weekly_salary: employee?.salary?.weekly_salary,
			hourly_salary: employee?.salary?.hourly_salary,
			expected_work_hours_per_week: employee?.salary?.expected_work_hours_per_week,
		});
	}, [employee, open]);
	
	function isSalaryValid() {
		if (employmentType === 'salaried') {
			return salary.annual_salary !== '' &&
				salary.monthly_salary !== '' &&
				salary.weekly_salary !== '' &&
				salary.expected_work_hours_per_week !== '';
		}
		else {
			return salary.hourly_salary !== '' &&
				salary.expected_work_hours_per_week !== '';
		}
	}

	return (
		<Modal isOpen={open} onClose={() => setOpen(false)} size={"md"} >
			<div style={{display: 'flex', gap: "0.8vw", flexDirection: "column"}}>
				<Typography variant={"title"}>
					Edit salary for {employee?.forename} {employee?.surname}
				</Typography>
				<div>
					<FreepayrollSelect
						options={salaryOptions}
						label={"Salary type"}
						value={employmentType}
						onSelect={(option) => {
							setEmploymentType(option.value);
							if (option.value !== employmentType && option.value !== undefined) {
								setSalary({
									annual_salary: '',
									monthly_salary: '',
									weekly_salary: '',
									hourly_salary: '',
									expected_work_hours_per_week: '',
								});
							}
						}}
					/>
				</div>
				<div style={{display: 'grid', gridTemplateColumns: "1fr 1fr", gap: "0.8vw", }}>
					{
						SalaryFields.map((field, index) => (
							(field.show ?? true) &&
							<div key={index} style={{width: '100%'}}>
								<FreepayrollTextField
									label={field.label}
									value={field.value}
									name={field.name}
									fieldType={"currency"}
									maxDecimalPlaces={2}
									disabled={field.disabled ?? false}
									onChange={(e) => handleChange(field.name, e.target.value)}
								/>
							</div>
						))
					}
				</div>
				<div style={{display: 'flex', gap: "0.8vw", flexDirection: "row"}}>
					<FreepayrollButton
						variant={"white-button"}
						onClick={() => setOpen(false)}
						isLoading={isPending}
					>
						Cancel
					</FreepayrollButton>
					<FreepayrollButton
						variant={"primary"}
						onClick={async () => {
							try {
								await updateEmployee({
									safeId: employee.safe_id,
									data: {
										employees: {
											action: 'salary',
											employment_type: employmentType,
											annual_salary: salary.annual_salary,
											monthly_salary: salary.monthly_salary,
											weekly_salary: salary.weekly_salary,
											hourly_salary: salary.hourly_salary,
											expected_work_hours_per_week: salary.expected_work_hours_per_week,
										}
									}
								});
								setOpen(false);
							} catch (e) {
								console.error(e);
							}
						}}
						isLoading={isPending}
						disabled={!isSalaryValid()}
						
					>
						Save
					</FreepayrollButton>
				</div>
			</div>
		</Modal>
	)
}

function PayScheduleModal ({
	open,
	setOpen,
	employee,
}) {
	
	const { mutateAsync: updateEmployee, isPending } = useUpdateEmployee();
	const { paySchedules , hasNextPage, fetchNextPage } = useInfinitePaySchedules();
	
	const [ selectedPaySchedule, setSelectedPaySchedule ] = useState(null);
	
	useEffect(() => {
		setSelectedPaySchedule(employee?.pay_schedule?.id);
	}, [employee, open]);
	
	useEffect(() => {
		if (hasNextPage) {
			fetchNextPage();
		}
	},[fetchNextPage, hasNextPage]);
	
	return (
		<Modal isOpen={open} onClose={() => setOpen(false)} size={"md"} >
			<div style={{display: 'flex', gap: "0.8vw", flexDirection: "column"}}>
				<Typography variant={"title"}>
					Change pay schedule for {employee?.forename} {employee?.surname}
				</Typography>
				<div>
					<FreepayrollSelect
						options={paySchedules.map(paySchedule => ({ label: paySchedule.name, value: paySchedule.id }))}
						label={"Pay schedule"}
						value={selectedPaySchedule}
						onSelect={(option) => {
							setSelectedPaySchedule(option.value);
						}}
					/>
				</div>
				<Alert severity="error">
					Are you sure you want to change this employee's pay schedule?
					If you proceed, the employee will be moved to the new pay schedule, and you will be
					responsible for ensuring that there are no overpayments or underpayments.
				</Alert>
				<div style={{display: 'flex', gap: "0.8vw", flexDirection: "row"}}>
					<FreepayrollButton
						variant={"white-button"}
						onClick={() => setOpen(false)}
						isLoading={isPending}
					>
						Cancel
					</FreepayrollButton>
					<FreepayrollButton
						variant={"primary"}
						isLoading={isPending}
						disabled={selectedPaySchedule === employee?.pay_schedule?.id}
						onClick={async () => {
							try {
								await updateEmployee({
									safeId: employee.safe_id,
									data: {
										employees: {
											action: 'pay_schedule',
											new_pay_schedule_id: selectedPaySchedule,
										}
									}
								});
								setOpen(false);
							} catch (e) {
								console.error(e);
							}
						}}
					>
						Save
					</FreepayrollButton>
				</div>
			</div>
		</Modal>
	)
}

function BoxItems({
	icon,
	title,
	subtitle,
	onClick,
}) {

	const style = {
		display: "flex",
		border: "1px solid #aaaeb7",
		maxWidth: "30.8334vw",
		padding: "1.5vw",
		cursor: "pointer",
		gap: "0.8vw",
		justifyContent: "space-between",
		alignItems: "center",
	}

	return (
		<div style={style} onClick={onClick}>
			{
				icon && (
					icon
				)
			}
			<div style={{width: subtitle ? '' : '60%', maxWidth: subtitle ? '' : "60%", textOverflow: "ellipsis", overflow: "hidden"}}>
				<Typography variant={"subtitle2"} color={"black"} className={styles.Typography}>{title}</Typography>
			</div>
			{
				subtitle && (
					<Typography variant={"subtitle2"} color={"black"}>{subtitle}</Typography>
				)
			}
			<KeyboardArrowRightIcon/>
		</div>
	);
}

export const SalaryTab = () => {

	const employeeId = useParams().employeeId;
	
	const { employee } = useGetEmployee({ id: employeeId, relations: ['salary','pay_schedule'] });

	const [openSalaryModal, setOpenSalaryModal] = useState(false);
	const [openPayScheduleModal, setOpenPayScheduleModal] = useState(false);

	const Boxes = [
		{
			icon: <CalendarMonthIcon/>,
			title: employee?.pay_schedule?.name,
			onClick: () => setOpenPayScheduleModal(true),
		},
		{
			title: employee?.salary?.employment_type === 'salaried' ? 'Annual Salary' : 'Hourly Salary',
			subtitle: employee?.salary?.employment_type === 'salaried' ?
				formatMoney(employee?.salary?.annual_salary) + ' / year'
				:
				formatMoney(employee?.salary?.hourly_salary) + ' / hour',
			onClick: () => setOpenSalaryModal(true),
		}
	];
	

	return (
		<div style={{display: "flex", flexDirection: "column", gap: "3vw" }}>
			<div>
				<Typography
					variant={"title"}
				>
					Modify employee pay schedule
				</Typography>
				<Typography
					variant={"title"}
				>
					Define employee base salary
				</Typography>
			</div>
			{
				Boxes.map((box, index) => (
					<BoxItems
						key={index}
						icon={box.icon}
						title={box.title}
						subtitle={box.subtitle}
						onClick={box.onClick}
					/>
				))
			}
			<SalaryModal
				open={openSalaryModal}
				setOpen={setOpenSalaryModal}
				employee={employee}
			/>
			<PayScheduleModal
				open={openPayScheduleModal}
				setOpen={setOpenPayScheduleModal}
				employee={employee}
			/>
		</div>
	);
};
