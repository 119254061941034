import { useState } from 'react';
import {
	FreepayrollButton,
	InputOtp,
	Typography,
} from '@collegia-partners/ui-kit';
import { useResend2FA, useVerify2FA } from '../../../hooks/user';
import { useNavigate } from 'react-router-dom';

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ConfirmTwoFactorAuthentication = (): JSX.Element => {
	const navigate = useNavigate();

	const { mutate: verify2fa, isPending: isVerifying } = useVerify2FA();

	const { mutate: resend2fa, isPending: isResending } = useResend2FA();

	const [pin, setPin] = useState('');

	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '3.83vw' }}>
			<div style={{ display: 'flex', flexDirection: 'column', gap: '0.83vw' }}>
				<Typography variant={'title'}>Two-step authentication</Typography>
				<Typography variant={'subtitle2'} color={'gray'} weight={'normal'}>
					Enter the authentication below code we sent to ********@gmail.com
				</Typography>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', gap: '1.83vw' }}>
				<InputOtp
					maxLength={6}
					value={pin}
					onChange={(value) => setPin(value)}
					radius="none"
					size="lg"
					activeBorder
					onComplete={() => {
						verify2fa({
							users: {
								code: pin,
							},
						});
					}}
				/>

				<Typography variant={'body'} color={'gray'} weight={'normal'}>
					The code will expire in 15 minutes
				</Typography>
			</div>
			<div style={{ display: 'flex', gap: '5px' }}>
				<Typography variant={'body'} color={'black'} weight={'normal'}>
					Didn’t receive a code?
				</Typography>
				<button
					style={{
						all: 'unset',
						cursor: 'pointer',
					}}
					onClick={() => {
						resend2fa();
					}}
					disabled={isResending}
				>
					<Typography variant={'body'} color={'black'} weight={'bold'}>
						{isResending ? 'Resending...' : 'Click here to resend'}
					</Typography>
				</button>
			</div>
			<div style={{ display: 'flex', gap: '5px' }}>
				<FreepayrollButton
					variant={'white-button'}
					custom
					onClick={() => {
						navigate('/main/settings/edit-info');
					}}
					isLoading={isVerifying}
					width={3.5}
					height={1}
				>
					Cancel
				</FreepayrollButton>
				<FreepayrollButton
					variant={'primary'}
					custom
					width={3.5}
					height={1}
					disabled={pin.length < 6}
					onClick={() => {
						verify2fa({
							users: {
								code: pin,
							},
						});
					}}
					isLoading={isVerifying}
				>
					Confirm
				</FreepayrollButton>
			</div>
		</div>
	);
};

export default ConfirmTwoFactorAuthentication;
