import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDateFromBackendWithTime } from '../../../utils/Helpers';
import { Dropbox, Icon, Typography, Chip } from '@collegia-partners/ui-kit';
import FPTable from '../../../components/common/FPTable/FPTable';
import {useDownloadGrossToNetReport, useListPayrolls} from '../../../hooks/payrolls';
import { useLoggedLayout } from '../../../context/LoggedLayoutContext';
import { renderPayRunStatusColor } from './PayrollActive';
import {useEmailAllPayslips} from "../../../hooks/payroll/useEmailAllPayslips";
import {useDownloadAllPayslips} from "../../../hooks/payroll/useDownloadAllPayslips";
import {useDownloadJournal} from "../../../hooks/reports/payroll-journal";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const PayrollArchivePage = (): JSX.Element => {
	const { setLayoutProps } = useLoggedLayout();

	const push = useNavigate();

	const { mutate: downloadGrossToNetReport, isPending: isDownloading } =
		useDownloadGrossToNetReport();

	const { mutate: emailAllPayslips, isPending: isEmailing } =
		useEmailAllPayslips();

	const { mutate: downloadAllPayslips, isPending: isDownloadingPayslips } =
		useDownloadAllPayslips();

	const { mutate: downloadJournal, isPending: isDownloadingJournal } =
		useDownloadJournal();

	const columns = [
		{
			text: 'Schedule Name',
			key: 'name',
			width: '1.5fr',
			hasOrderBy: true,
		},
		{
			text: 'Tax Period',
			key: 'tax_period',
			hasOrderBy: true,
		},
		{
			text: 'Pay Date',
			key: 'period_end_date',
			customRender: (row, index) => (
				<Typography variant="body" size="medium" key={index} color={'black'}>
					{formatDateFromBackendWithTime(row.period_end_date)}
				</Typography>
			),
		},
		{
			text: 'Status',
			key: 'status',
			customRender: (row, index) => (
				<Chip key={index} color={renderPayRunStatusColor(row.status)}>
					{row.status}
				</Chip>
			),
		},
		{
			text: 'Actions',
			width: '1.5fr',
			customRender: (row, index) => (
				<Dropbox
					target={
						<button style={{ all: 'unset', cursor: 'pointer' }}>
							<Icon
								iconName="dots-horizontal"
								className={'EmployeeSummaryIcon'}
							/>
						</button>
					}
					key={index}
					className={'EmployeeSummaryActions'}
				>
					<button
						onClick={() => push(`/main/view-payroll/${btoa(row.id)}`)}
						className={'EmployeeSummaryAction'}
					>
						View Payroll
					</button>
					<button
						onClick={() => push(`/main/view-payroll/${btoa(row.id)}`)}
						disabled={isDownloading || isEmailing}
						className={'EmployeeSummaryAction'}
					>
						View Payroll
					</button>
					<button
						onClick={() => {
							emailAllPayslips(row.id);
						}}
						disabled={isDownloading || isEmailing || isDownloadingJournal}
						className={'EmployeeSummaryAction'}
					>
						{isEmailing ? 'Sending...' : 'Email all payslips'}
					</button>
					<button
						onClick={() => {
							downloadAllPayslips(row.id);
						}}
						disabled={isDownloading || isEmailing || isDownloadingJournal}
						className={'EmployeeSummaryAction'}
					>
						{isDownloadingPayslips
							? 'Downloading...'
							: 'Download all payslips'}
					</button>
					<button
						onClick={() => {
							downloadGrossToNetReport(row.id);
						}}
						disabled={isDownloading || isEmailing || isDownloadingJournal}
						className={'EmployeeSummaryAction'}
					>
						{isDownloading
							? 'Downloading...'
							: 'Download Gross to Net Report'}
					</button>
					<button
						onClick={() => {
							downloadJournal({
								journal_template: {
									employer_pay_schedule_run_id: row?.id,
								},
							});
						}}
						disabled={isDownloading || isEmailing || isDownloadingJournal}
						className={'EmployeeSummaryAction'}
					>
						{isDownloadingJournal ? 'Downloading...' : 'Download Journal'}
					</button>
				</Dropbox>
			),
		},
	];

	useEffect(() => {
		setLayoutProps({
			activePage: 'Payroll',
			activeSubpage: 'Archived',
			showBackButton: false,
		});
	}, [setLayoutProps]);

	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				gap: '2.57vw',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Typography
					variant={'title'}
					size={'large'}
					color={'primary'}
					weight={'bold'}
				>
					Payroll Archive
				</Typography>
			</div>
			<div>
				<FPTable
					headers={columns}
					hookName={useListPayrolls}
					hookKey={'payrolls'}
					hookParams={{
						mode: 'archive',
					}}
					paginate={true}
				/>
			</div>
		</div>
	);
};

export default PayrollArchivePage;
