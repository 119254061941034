import React, { useCallback, useEffect, useState } from 'react';
import StyleTypography from '../../../components/StyledComponents/StyleTypography';
import Divider from '@mui/material/Divider';
import House from '../../../images/svg/hmrc-page/black-house.svg';
import Plane from '../../../images/svg/hmrc-page/black-paper-plane.svg';
import EditIcon from '@mui/icons-material/Edit';
import { useLoggedLayout } from '../../../context/LoggedLayoutContext';
import {
	useGetHmrcSettings, useTestHmrcConnection,
	useUpdateHmrcSettings,
} from '../../../hooks/hmrc-settings';
import {
	FreepayrollButton,
	FreepayrollTextField,
	Modal,
	Typography,
	Switch,
} from '@collegia-partners/ui-kit';
import { toast } from 'sonner';
import Skeleton from '../../../components/Skeleton';

function Row({ index, label, value, isLoading, isLast }) {
	return (
		<div key={index} style={{ width: '100%' }}>
			<div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						width: '100%',
						padding: '1.3vw 0',
					}}
				>
					<Typography variant={'subtitle2'} color={'black'} weight={'normal'}>
						{label}
					</Typography>
					{isLoading ? (
						<Skeleton width={'50%'} background={'#e0e0e0'} />
					) : (
						<Typography variant={'subtitle2'} color={'black'} weight={'bold'}>
							{value}
						</Typography>
					)}
				</div>
				{!isLast && <Divider className={'divider'} />}
			</div>
		</div>
	);
}

function EditHmrcSettings({
	open,
	onClose,
	employerRecord = {},
	setEmployerRecord = () => {},
}) {
	const { mutateAsync: updateSettings, isPending: isLoading } =
		useUpdateHmrcSettings({
			onSuccess: () => {
				onClose();
				toast.success('HMRC settings updated');
			},
		});

	const { mutate: testConnection, isPending } = useTestHmrcConnection();

	const onSubmit = useCallback(async () => {
		await updateSettings({
			rti_keys: {
				paye_reference: employerRecord?.paye_reference,
				account_office_reference: employerRecord?.account_office_reference,
				user: employerRecord?.user,
				password: employerRecord?.password,
			},
		});
	}, [
		updateSettings,
		employerRecord?.paye_reference,
		employerRecord?.account_office_reference,
		employerRecord?.user,
		employerRecord?.password,
	]);

	const regex_office_reference = /^[0-9]{3}P[A-Z][0-9]{7}[0-9X]$/;
	const regex_paye_reference = /^\d{3}\/.*$/i;

	return (
		<Modal isOpen={open} onClose={onClose}>
			<div style={{ display: 'flex', flexDirection: 'column', gap: '1.83vw', marginTop: '1.83vw' }}>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<Typography variant={'title'}>Edit HMRC settings</Typography>
					<FreepayrollButton
						variant={'blue-with-radius'}
						onClick={() => {
							testConnection({
								rti_keys: {
									paye_reference: employerRecord?.paye_reference,
									account_office_reference:
									employerRecord?.account_office_reference,
									user: employerRecord?.user,
									password: employerRecord?.password,
								},
							});
						}}
						isLoading={isLoading || isPending}
						size={'small'}
						custom={true}

					>
						Test Connection
					</FreepayrollButton>
				</div>

				<div style={{ display: 'flex', flexDirection: 'row', gap: '1.03vw' }}>
					<FreepayrollTextField
						fieldType={'text'}
						name={'paye_reference'}
						label={'PAYE reference'}
						value={employerRecord?.paye_reference}
						error={!regex_paye_reference.test(employerRecord?.paye_reference)}
						helperText={
							!regex_paye_reference.test(employerRecord?.paye_reference)
								? 'Invalid PAYE reference'
								: ''
						}
						onChange={(e) =>
							setEmployerRecord({
								...employerRecord,
								paye_reference: e.target.value,
							})
						}
					/>
					<FreepayrollTextField
						fieldType={'text'}
						name={'account_office_reference'}
						label={'Accounts office reference'}
						value={employerRecord?.account_office_reference}
						error={
							!regex_office_reference.test(
								employerRecord?.account_office_reference,
							)
						}
						helperText={
							!regex_office_reference.test(
								employerRecord?.account_office_reference,
							)
								? 'Invalid Accounts office reference'
								: ''
						}
						onChange={(e) =>
							setEmployerRecord({
								...employerRecord,
								account_office_reference: e.target.value,
							})
						}
					/>
				</div>
				<div style={{ display: 'flex', flexDirection: 'row', gap: '1.03vw' }}>
					<FreepayrollTextField
						fieldType={'text'}
						name={'user'}
						label={'HMRC Gateway User ID'}
						maxLength={12}
						value={employerRecord?.user}
						onChange={(e) =>
							setEmployerRecord({ ...employerRecord, user: e.target.value })
						}
					/>
					<FreepayrollTextField
						fieldType={'password'}
						name={'password'}
						label={'HMRC Gateway Password'}
						value={employerRecord?.password}
						onChange={(e) =>
							setEmployerRecord({ ...employerRecord, password: e.target.value })
						}
					/>
				</div>
				<div style={{ display: 'flex', flexDirection: 'row', gap: '1.03vw' }}>
					<FreepayrollButton
						variant={'white-button'}
						onClick={onClose}
						isLoading={isLoading || isPending}
					>
						Cancel
					</FreepayrollButton>
					<FreepayrollButton
						variant={'primary'}
						onClick={onSubmit}
						disabled={
							!regex_office_reference.test(
								employerRecord?.account_office_reference,
							) ||
							!regex_paye_reference.test(employerRecord?.paye_reference) ||
							employerRecord?.user === '' ||
							employerRecord?.password === '' ||
							!employerRecord?.user ||
							!employerRecord?.password
						}
						isLoading={isLoading || isPending}
					>
						Save
					</FreepayrollButton>
				</div>
			</div>
		</Modal>
	);
}

function EditAutoEpsSubmission({ open, onClose, employerRecord = {} }) {
	const { mutateAsync: updateSettings, isPending: isLoading } =
		useUpdateHmrcSettings();

	return (
		<Modal isOpen={open} onClose={onClose}>
			<div style={{ display: 'flex', flexDirection: 'column', gap: '1.83vw' }}>
				<Typography variant={'title'}>
					{employerRecord?.eps_automatic
						? 'Are you sure you want to disable automatic EPS submission?'
						: 'Are you sure you want to enable automatic EPS submission?'}
				</Typography>
				<Typography variant={'subtitle'} color={'black'} weight={'normal'}>
					{employerRecord?.eps_automatic
						? 'If you disable automatic EPS submission, you will need to handle it manually'
						: 'Enabling automatic EPS submission will automatically submit EPS to HMRC, every ' +
						  '18th of the month. You will still be able to send any custom submission, ' +
						  'by accessing the reports section.'}
				</Typography>
				<div style={{ display: 'flex', flexDirection: 'row', gap: '1.03vw' }}>
					<FreepayrollButton
						variant={'white-button'}
						onClick={onClose}
						isLoading={isLoading}
					>
						Cancel
					</FreepayrollButton>
					<FreepayrollButton
						variant={'primary'}
						onClick={async () => {
							await updateSettings({
								rti_keys: {
									eps_automatic: !employerRecord?.eps_automatic,
								},
							});
							onClose();
							toast.success('HMRC settings updated successfully');
						}}
						isLoading={isLoading}
					>
						{employerRecord?.eps_automatic ? 'Disable' : 'Enable'}
					</FreepayrollButton>
				</div>
			</div>
		</Modal>
	);
}

const HMRC = () => {
	const { setLayoutProps } = useLoggedLayout();

	const { hmrcSettings, isPending } = useGetHmrcSettings();

	const [showEditModal, setShowEditModal] = useState(false);
	const [employerRecord, setEmployerRecord] = useState({});
	const [openEpsModal, setOpenEpsModal] = useState(false);

	const HMRCItems = [
		{
			label: 'PAYE reference',
			value: hmrcSettings?.paye_reference,
		},
		{
			label: 'Accounts office reference',
			value: hmrcSettings?.account_office_reference,
		},
		{
			label: 'HMRC Gateway User ID',
			value: hmrcSettings?.user,
		},
		{
			label: 'HMRC Gateway Password',
			value: 'xxxxxx',
		},
	];

	useEffect(() => {
		setEmployerRecord(hmrcSettings);
	}, [hmrcSettings]);

	useEffect(() => {
		setLayoutProps({
			activePage: 'HMRC',
			showBackButton: false,
		});
	}, [setLayoutProps]);

	return (
		<div id={'hmrc-page'}>
			<div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
					}}
				>
					<StyleTypography
						fontSize={40}
						fontSizeMedium={30}
						fontWeight={'bold'}
					>
						Company HMRC settings
					</StyleTypography>
					<div style={{ paddingTop: '10px', paddingBottom: '30px' }}>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={13}
							color={'#ABAEB7'}
						>
							Credentials for HMRC reporting and RTI submissions
						</StyleTypography>
					</div>
				</div>
				<Divider className={'divider'} />
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						width: '100%',
						flexDirection: 'row',
					}}
					className={'information-container'}
				>
					<div
						style={{ display: 'flex', flexWrap: 'wrap', width: '43.1667%' }}
						className={'left-container'}
					>
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								width: '100%',
							}}
							className={'edit'}
						>
							<div
								style={{ cursor: 'pointer' }}
								onClick={() => setShowEditModal(true)}
							>
								<StyleTypography
									textAlign={'end'}
									fontWeight={'bold'}
									fontSize={16}
									fontSizeMedium={13}
									color={'black'}
								>
									Edit
									<EditIcon className={'icon'} />
								</StyleTypography>
							</div>
						</div>
						<Divider className={'divider'} />
						{HMRCItems.map((item, index) => (
							<Row
								key={index}
								label={item.label}
								value={item.value}
								isLoading={isPending}
								isLast={index === HMRCItems.length - 1}
							/>
						))}
					</div>
					<div
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							width: '43.1667%',
							marginLeft: '36px',
							alignItems: 'flex-start',
							alignSelf: 'flex-start',
						}}
						className={'right-container'}
					>
						<div
							style={{ display: 'flex', flexWrap: 'wrap' }}
							className={'information-item-grid'}
						>
							<div style={{ width: '100%' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ marginRight: '20px' }}>
										<img alt={'logo'} src={House} className={'right-icon'} />
									</div>
									<StyleTypography
										fontSize={22}
										color={'black'}
										fontSizeMedium={16}
										fontWeight={'bold'}
									>
										HMRC
									</StyleTypography>
								</div>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<StyleTypography
										fontSize={18}
										fontSizeMedium={13}
										color={'#AAAEB7'}
									>
										PAYE is the HMRC system for collecting income tax and
										National Insurance from employment.
									</StyleTypography>
								</div>
							</div>
						</div>
						<Divider className={'divider'} />
						<div
							style={{ display: 'flex', flexWrap: 'wrap' }}
							className={'information-item-grid'}
						>
							<div style={{ width: '100%' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ marginRight: '20px' }}>
										<img alt={'logo'} src={Plane} className={'right-icon'} />
									</div>
									<StyleTypography
										fontSize={22}
										color={'black'}
										fontSizeMedium={16}
										fontWeight={'bold'}
									>
										RTI Submission
									</StyleTypography>
								</div>
								<div>
									<StyleTypography
										fontSize={18}
										fontSizeMedium={13}
										color={'#AAAEB7'}
									>
										Each time you run payroll for your employees, we perform a
										RTI Submission to HMRC.
									</StyleTypography>
								</div>
							</div>
						</div>
						<Divider className={'divider'} />
						<div
							style={{ display: 'flex', flexWrap: 'wrap' }}
							className={'information-item-grid'}
						>
							<Switch
								isChecked={hmrcSettings?.eps_automatic}
								onCheckChange={() => setOpenEpsModal(true)}
								textPosition={'left'}
								label={`Automatic EPS submission`}
								labelTypographyProps={{
									color: 'black',
									weight: 'bold',
									variant: 'subtitle2',
								}}
							/>
						</div>
					</div>
				</div>
			</div>
			<EditHmrcSettings
				open={showEditModal}
				onClose={() => {
					setShowEditModal(false);
					setEmployerRecord(hmrcSettings);
				}}
				employerRecord={employerRecord}
				setEmployerRecord={setEmployerRecord}
			/>
			<EditAutoEpsSubmission
				open={openEpsModal}
				onClose={() => {
					setOpenEpsModal(false);
					setEmployerRecord(hmrcSettings);
				}}
				employerRecord={employerRecord}
			/>
		</div>
	);
};

export default HMRC;
