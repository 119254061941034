import { useEffect, useState } from 'react';
import { Step, StepContent, StepLabel, Stepper } from '@mui/material';
import StyleTypography from '../../../components/StyledComponents/StyleTypography';
import gray from '../../../images/svg/aro 1.svg';
import blue from '../../../images/svg/aro 2.svg';
import check from '../../../images/svg/aro 3.svg';
import { useNavigate } from 'react-router-dom';
import { FreepayrollButton } from '@collegia-partners/ui-kit';
import { useGetEmployer } from '../../../hooks/employer';
import Loading from '../../../components/Loading';
import { useLoggedLayout } from '../../../context/LoggedLayoutContext';

function CustomStepIcon(props) {
	const { active, completed } = props;

	if (active) {
		return <img src={blue} alt={'blue'} />;
	} else if (completed) {
		return <img src={check} alt={'check'} />;
	} else {
		return <img src={gray} alt={'gray'} />;
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const OnboardingDashboard = () => {
	const steps = [
		{
			label: 'Step 1',
			optional: (
				<StyleTypography fontSize={20} fontSizeMedium={15} fontWeight={'bold'}>
					Create company details
				</StyleTypography>
			),
			description: 'Create your company details',
			link: '/company-details',
		},
		{
			label: 'Step 2',
			optional: (
				<StyleTypography fontSize={20} fontSizeMedium={15} fontWeight={'bold'}>
					Basic Setup
				</StyleTypography>
			),
			description: 'Payroll dates, HMRC settings and Automatic Enrolment',
			link: '/onboarding/basic-setup',
		},
		{
			label: 'Step 3',
			optional: (
				<StyleTypography fontSize={20} fontSizeMedium={15} fontWeight={'bold'}>
					Pay Schedule
				</StyleTypography>
			),
			description: `Let's add your default pay schedule. You will be able to add more later.`,
			link: '/onboarding/pay-schedule',
		},
		{
			label: 'Step 4',
			optional: (
				<StyleTypography fontSize={20} fontSizeMedium={15} fontWeight={'bold'}>
					Adding employees
				</StyleTypography>
			),
			description: `Easily set up employees in FreePayroll it will only take a couple of minutes`,
			link: '/onboarding/add-employee-method',
		},
	];

	const push = useNavigate();
	const [activeStep, setActiveStep] = useState(100);

	const { employer, isPending: isLoadingEmployer } = useGetEmployer({
		relations: ['pay_schedules'],
	});
	const { setLayoutProps } = useLoggedLayout();
	
	useEffect(() => {
		if (isLoadingEmployer) return;
		if (!employer?.id) {
			setActiveStep(0);
		} else if (!employer?.basic_setup_complete) {
			setActiveStep(1);
		} else if (employer?.pay_schedules?.length <= 0) {
			setActiveStep(2);
		} else {
			setActiveStep(3);
		}
	}, [employer, isLoadingEmployer]);

	useEffect(() => {
		setLayoutProps({
			isOnboarding: true,
			activePage: 'Setup',
		});
	}, [setLayoutProps]);

	if (isLoadingEmployer) {
		return <Loading />;
	}

	return (
		<div
			style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
			id={'onboarding-dashboard-page'}
		>
			<div style={{ display: 'flex', flexWrap: 'wrap' }}>
				<div style={{ flex: '0 0 100%', maxWidth: '100%' }}>
					<div>
						<StyleTypography
							fontSize={40}
							fontSizeMedium={31}
							fontWeight={'bold'}
						>
							Your payroll setup roadmap
						</StyleTypography>
					</div>
					<div className={'sub-title-container'}>
						<StyleTypography
							fontSize={18}
							fontSizeMedium={14}
							color={'#ABAEB7'}
						>
							Let’s finish setting up your payroll. Just a few more steps and
							you’re ready <br />
							to run your first payroll with FreePayroll!
						</StyleTypography>
					</div>
				</div>
				<div id={'stepper-container'}>
					<Stepper activeStep={activeStep} orientation="vertical">
						{steps.map((step, index) => (
							<Step key={step.label}>
								<StepLabel StepIconComponent={CustomStepIcon}>
									{step.optional}
								</StepLabel>
								<StepContent>
									<div
										id={'step-content-container'}
										style={{
											display: 'flex',
											flexWrap: 'nowrap',
											gap: '2.604vw',
											alignItems: 'center',
										}}
									>
										<div>
											<StyleTypography
												fontSize={20}
												fontSizeMedium={15}
												color={'#FFFFFF'}
												fontWeight={'bold'}
											>
												{step.description}
											</StyleTypography>
											<StyleTypography
												fontSize={16}
												fontSizeMedium={12}
												color={'#A6B1C9'}
											>
												7 minutes
											</StyleTypography>
										</div>
										<div className="step-white-button">
											<FreepayrollButton
												variant="white-button"
												onClick={() => push(step.link)}
												size="xsmall"
											>
												Continue
											</FreepayrollButton>
										</div>
									</div>
								</StepContent>
							</Step>
						))}
					</Stepper>
				</div>
			</div>
		</div>
	);
};

export default OnboardingDashboard;
