import arrow from '../images/svg/arrow-diagonal.svg';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '../components/Loading';
import {
	FreepayrollButton,
	SegmentedControl,
	FreepayrollTextField,
	Typography,
	InputOtp,
} from '@collegia-partners/ui-kit';
import DialogContent from '@mui/material/DialogContent';
import StyleTable from '../components/StyledComponents/StyleTable';
import Dialog from '@mui/material/Dialog';
import LogoImage from '../images/svg/Logos/fp-white-symbol.svg';
import {
	useLogin,
	useLoginTwoFactorAuth,
	useResend2FA,
	useSelectAccount,
} from '../hooks/user';

function TwoFactorAuth({ setAccounts }) {
	const { mutate: loginTwoFactor, isPending } =
		useLoginTwoFactorAuth(setAccounts);

	const { mutate: resend2fa, isPending: isResending } = useResend2FA();

	const [pin, setPin] = useState('');

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '1.83vw',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Typography variant="title" size="xlarge">
				Two Factor Authentication
			</Typography>
			<Typography variant="body" size="medium" color="black">
				Please enter the code sent to your mobile phone.
			</Typography>
			<div style={{ display: 'flex', flexDirection: 'column', gap: '1.83vw' }}>
				<InputOtp
					maxLength={6}
					value={pin}
					onChange={(value) => setPin(value)}
					radius="none"
					size="lg"
					activeBorder
					onComplete={() => {
						try {
							loginTwoFactor({
								users: {
									code: pin,
								},
							});
						} catch (e) {
							console.error(e);
						}
					}}
				/>

				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<Typography variant={'body'} color={'gray'} weight={'normal'}>
						The code will expire in 15 minutes
					</Typography>
					<button
						style={{
							all: 'unset',
							cursor: 'pointer',
						}}
						onClick={() => {
							resend2fa();
						}}
						disabled={isResending}
					>
						<Typography variant={'body'} color={'black'} weight={'bold'}>
							{isResending ? 'Resending...' : 'Click here to resend'}
						</Typography>
					</button>
				</div>
			</div>
			<div
				style={{
					width: '70%',
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<FreepayrollButton
					variant={'primary'}
					disabled={pin.length !== 6}
					onClick={() => {
						try {
							loginTwoFactor({
								users: {
									code: pin,
								},
							});
						} catch (e) {
							console.error(e);
						}
					}}
					isLoading={isPending}
				>
					Verify
				</FreepayrollButton>
			</div>
		</div>
	);
}

function LoginDetails({ setStep, setAccounts }) {
	const { mutateAsync: login, isPending } = useLogin(setStep, setAccounts);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const navigate = useNavigate();

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '1.83vw',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Typography variant="title" size="xlarge">
				Welcome to FreePayroll
			</Typography>
			<div
				style={{
					width: '70%',
					marginLeft: 'auto',
					marginRight: 'auto',
					marginBottom: '1.041vw',
				}}
			>
				<SegmentedControl
					size="small"
					options={[
						{ label: 'Employer', value: 'employer' },
						{ label: 'Bureau', value: 'bureau' },
					]}
					onSelectedChange={(value) => {
						if (value === 'employer') return;
						window.location.href = 'https://bureau.freepayroll.uk';
					}}
				/>
			</div>
			<div
				style={{
					width: '70%',
					marginLeft: 'auto',
					marginRight: 'auto',
					marginBottom: '1.041vw',
					gap: '1.041vw',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<FreepayrollTextField
					fieldType={'text'}
					label={'Email*'}
					value={email}
					onChange={(event) => {
						setEmail(event.target.value.toLowerCase());
					}}
					name={'email'}
				/>
				<FreepayrollTextField
					fieldType={'password'}
					name={'password'}
					label={'Password*'}
					value={password}
					onChange={(event) => setPassword(event.target.value)}
					helperText={<a href={'/forgot-password'}>Forgot your password?</a>}
				/>
				<FreepayrollButton
					variant={'primary'}
					fullWidth={true}
					disabled={!email || !password}
					onClick={async () => {
						try {
							await login({
								users: {
									email,
									password,
								},
							});
						} catch (e) {
							console.error(e);
						}
					}}
					isLoading={isPending}
				>
					Login
				</FreepayrollButton>
			</div>
			<button
				onClick={() => {
					navigate('/sign-up');
				}}
				style={{ all: 'unset', cursor: 'pointer' }}
			>
				<Typography
					variant="subtitle2"
					size="medium"
					color="black"
					weight={'bold'}
				>
					I'm not a customer yet
					<img src={arrow} alt={'diagonal arrow'} />
				</Typography>
			</button>
		</div>
	);
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const Login = (): JSX.Element => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [open, setOpen] = useState(false);
	const [accounts, setAccounts] = useState([]);

	const { mutate: selectAccount, isPending: isSelectingAccount } =
		useSelectAccount();

	const [step, setStep] = useState(0);

	const columns = [
		{
			name: 'employer.company_name',
			label: 'Account',
			width: 200,
		},

		{
			name: 'employer.id',
			label: 'Action',
			options: {
				customBodyRender: (value) => (
					<Button
						className={'default-black-button'}
						variant={'contained'}
						fullWidth
						onClick={() => {
							selectAccount({
								users: {
									acc_id: value,
								},
							});
						}}
						disabled={isSelectingAccount}
					>
						Login
					</Button>
				),
			},
		},
	];

	useEffect(() => {
		if (searchParams.get('token') !== null) {
			localStorage.removeItem('fpEmployerToken');
			setTimeout(() => {
				localStorage.setItem('fpEmployerToken', searchParams.get('token'));
				if (searchParams.get('payrollId') !== null) {
					window.location.href =
						'/main/run-payroll/' + searchParams.get('payrollId');
				} else {
					window.location.href = '/main';
				}
			}, 500);
		}
		setOpen(accounts.length > 0);
	}, [accounts, navigate, searchParams]);

	if (searchParams.get('token') !== null) {
		return <Loading />;
	}

	return (
		<div className="login-container">
			<div id={'login-image'}>
				<img src={LogoImage} alt={'Logo'} className={'logo'} />
			</div>
			{step === 0 && (
				<LoginDetails setAccounts={setAccounts} setStep={setStep} />
			)}
			{step === 1 && <TwoFactorAuth />}
			<Dialog
				onClose={() => {
					setOpen(false);
				}}
				open={open}
				fullWidth={true}
			>
				<DialogContent>
					<StyleTable
						data={accounts || []}
						columns={columns}
						title={'Accounts'}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Login;
