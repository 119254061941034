// UserContext.js
import React, {createContext, useContext, useEffect} from 'react';
import {useMe} from "../hooks/user";
import {Crisp} from "crisp-sdk-web";
import {useNavigate} from "react-router-dom";
import {Loading} from "@collegia-partners/ui-kit";

// Create a Context
const UserContext = createContext();

// Create a Provider component
export function UserProvider({ children }) {
	const { profileData, isPending, isError } = useMe();

	const navigate = useNavigate();

	useEffect(() => {
		if (profileData?.id) {
			Crisp.session.setData({
				user_id: profileData?.id,
			});
		}
		if (profileData?.email) {
			Crisp.user.setEmail(profileData?.email);
		}
		if (profileData?.name) {
			Crisp.user.setNickname(profileData?.name);
		}
		if (profileData?.phone_number) {
			Crisp.user.setPhone(profileData?.phone_number);
		}
		if (profileData?.company_name) {
			Crisp.user.setCompany(profileData?.company_name);
			Crisp.session.setData({
				employer_id: profileData?.employer_id,
			});
		}
		if (profileData?.id && !profileData?.onboarding_complete && window.location.pathname.includes('/main')) {
			navigate('/onboarding/dashboard/');
		}
		if (profileData?.id && !profileData?.email_verified_at) {
			navigate('/confirm-email');
		}
	}, [navigate, profileData]);

	return (
		<UserContext.Provider value={{ profileData, isPending, isError }}>
			{
				isPending ? (
					<div style={{ display: 'flex',height: '100dvh' }} >
						<Loading size={'large'} />
					</div>
				) : (
					children
				)
			}
		</UserContext.Provider>
	);
}

// Custom hook to use the UserContext
export function useUser() {
	return useContext(UserContext);
}
